import PropTypes from "prop-types";
import { useCallback, lazy, useEffect, useRef, useState, Component, cloneElement } from 'react';
import {
    Box,
    Button,
    Stack,
    SvgIcon,
    Typography,
    Unstable_Grid2 as Grid,
    useMediaQuery,
    IconButton,
    Tooltip,
    Badge,
} from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

import * as Sentry from "@sentry/react";

import { StopCircleOutlined } from '@mui/icons-material';
import { canadianProvinces, countries, getCountryCode, removeEmojis } from 'src/utils/locations';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import 'react-international-phone/style.css';

import { AudioVisualizer } from 'react-audio-visualize';
import FailSafeAvatar from 'src/pages/components/fail-safe-avatar';
import { keyframes } from '@emotion/react';
import ReactCountryFlag from 'react-country-flag';


function filterFlagEmoji(text) {
    if (!text) {
        return null;
    }
    const flagEmojiPattern = /(?:\uD83C[\uDDE6-\uDDFF]){2}/g;
    return text.match(flagEmojiPattern);
}


const slideUp = keyframes`
  0% {
    transform: translate3d(0, 5vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const AmbassadorProfile = ({ ambassador, expandedProfile, widgetConfig = {}, isAvailable = false, schoolColor = 'darkblue', schoolType = null, globalAudio, onToggleAudio, onChatWith, onAddFilter = () => { }, fromMatchMaker = false }) => {
    const containerRef = useRef(null);
    const scrollDirection = useRef(1);
    const [scrollIntervalId, setScrollIntervalId] = useState(null);
    const [showScroll, setShowScroll] = useState(false);
    const [blob, setBlob] = useState(null);
    const [boxWidth, setBoxWidth] = useState(0);
    const [audioColor, setAudioColor] = useState('lightblue');
    const [audioCurrentTime, setAudioCurrentTime] = useState(0);
    const [audioURL, setAudioURL] = useState(null);
    const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm')) || fromMatchMaker;

    const boxRef = useRef(null);

    const startScrolling = () => {
        if (scrollIntervalId) {
            clearInterval(scrollIntervalId);
        }

        const id = setInterval(() => {
            if (containerRef.current) {
                containerRef.current.scrollLeft += scrollDirection.current;

                if (containerRef.current.scrollLeft >= containerRef.current.scrollWidth - containerRef.current.offsetWidth - 1) {
                    scrollDirection.current = -1;
                } else if (containerRef.current.scrollLeft <= 0) {
                    scrollDirection.current = 1;
                }
            }
        }, 20);

        setScrollIntervalId(id);
        setShowScroll(true);
    };

    const stopScrolling = () => {
        if (scrollIntervalId) {
            clearInterval(scrollIntervalId);
            setScrollIntervalId(null);
        }
    };

    // TODO optimize such that we create a dummy blob and only load the audio once pressed
    useEffect(() => {
        if (ambassador?.profileVoiceNoteURL) {
            fetch(ambassador.profileVoiceNoteURL)
                .then((response) => response.blob())
                .then((blob) => {
                    setBlob(blob);
                });
        }
    }, [ambassador?.profileVoiceNoteURL]);

    useEffect(() => {
        // Create a new URL that is playable
        if (blob && globalAudio) {
            const blobURL = URL.createObjectURL(blob);
            setAudioURL(blobURL);
            // globalAudio.src = audioURL;
            // globalAudio.load();

            const onTimeUpdate = () => {
                if (globalAudio.src === blobURL) {
                    setAudioCurrentTime(globalAudio.currentTime);
                    setAudioColor('lightblue');
                }
            };

            const onEnded = () => {
                if (globalAudio.src === blobURL) {
                    globalAudio.currentTime = 0;
                    globalAudio.pause();
                }
            };

            const onPaused = () => {
                if (globalAudio.src === blobURL) {
                    globalAudio.currentTime = 0;
                    setAudioCurrentTime(0);
                }
            }

            // Detect if the source changes
            const onLoadStart = () => {
                setAudioCurrentTime(0);
            }

            globalAudio.addEventListener('timeupdate', onTimeUpdate);
            globalAudio.addEventListener('ended', onEnded);
            globalAudio.addEventListener('pause', onPaused);
            globalAudio.addEventListener('loadstart', onLoadStart);

            return () => {
                URL.revokeObjectURL(blobURL);
                globalAudio.removeEventListener('timeupdate', onTimeUpdate);
                globalAudio.removeEventListener('ended', onEnded);
                globalAudio.removeEventListener('pause', onPaused);
                globalAudio.removeEventListener('loadstart', onLoadStart);

                if (globalAudio.src === blobURL) {
                    globalAudio.pause();
                }
            };
        }
    }, [blob]);

    useEffect(() => {
        if (boxRef.current) {
            setBoxWidth(boxRef.current.offsetWidth);
        }
    }, []);

    // On window resize
    useEffect(() => {
        const handleResize = () => {
            if (boxRef.current) {
                setBoxWidth(boxRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const buttonRoundedness = widgetConfig.ctaButtonRoundness ?? 25;
    const backgroundColor = widgetConfig.backgroundRGB ?? "243,243,243";

    const hideHomeTown = widgetConfig.hideHomeTown ?? false;
    const hideMinor = widgetConfig.hideMinor ?? false;
    const hideAboutMe = fromMatchMaker || (widgetConfig.hideAboutMe ?? false);

    if (!ambassador) {
        return null;
    }

    return (
        <Grid
            item="true"
            xs={12}
            sm={6}
            md={4}
            sx={{
                p: 0.8,
                animation: `${slideUp} 0.1s ease-in-out`,
                cursor: matchDownSm ? "pointer" : "default",
            }}
            onMouseEnter={startScrolling}
            onMouseLeave={() => {
                stopScrolling();
                setShowScroll(false);
            }}
            onClick={matchDownSm ? () => onChatWith(ambassador) : null}
        >
            <Stack alignItems="center" sx={{ backgroundColor: `rgb(${backgroundColor})`, padding: matchDownSm ? "3%" : "7%", borderRadius: "35px", paddingBottom: hideAboutMe && !matchDownSm ? "9%" : matchDownSm ? '4%' : "7%" }}>
                {
                    !matchDownSm && (
                        <Tooltip tabIndex={0} title={fromMatchMaker ? "" : `Search '${ambassador.ambassadorType}'`} placement='top' onClick={() => onAddFilter({ type: "Tag", value: ambassador.ambassadorType, id: ambassador.ambassadorType })}>
                            <Typography
                                sx={{
                                    fontSize: "0.9em",
                                    mb: 1,
                                    backgroundColor: "white",
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    borderColor: "gray",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                    paddingTop: "5px",
                                    paddingBottom: "5px",
                                    cursor: "pointer",
                                    '&:hover': {
                                        borderColor: (theme) => theme.palette.primary.main,
                                        fontWeight: 600,
                                    }
                                }}
                            >
                                {ambassador.ambassadorType}
                            </Typography>
                        </Tooltip>
                    )
                }
                <Stack direction="row" justifyContent={"space-between"} width={matchDownSm ? "100%" : "unset"}>
                    <Stack direction="row" alignSelf={matchDownSm ? "flex-start" : "center"}>
                        <Stack>
                            <Tooltip title={isAvailable ? "Online Now" : ""}>
                                <FailSafeAvatar
                                    profilePicURL={ambassador.photoURL}
                                    lastName={ambassador.lastName}
                                    cartoonOptions={ambassador.avatarOptions}
                                    sz={matchDownSm ? 70 : 90}
                                    sx={{
                                        borderStyle: 'solid',
                                        borderWidth: 4,
                                        borderColor: 'black',
                                        mb: 1,
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title={isAvailable ? "Online Now" : ""}>
                                <Badge sx={{ "& .MuiBadge-badge": { backgroundColor: isAvailable ? "#4CBB17" : /*"#F4BB44"*/ "none" }, position: "relative", marginTop: "-1.2em", marginRight: 0, marginBottom: "1em" }} badgeContent=" " overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} />
                            </Tooltip>
                            <Typography
                                sx={{
                                    fontSize: "1.3em", fontWeight: matchDownSm ? 600 : 500, cursor: !!ambassador.linkedInURL ? "pointer" : "default", ':hover': {
                                        color: !!ambassador.linkedInURL ? (theme) => theme.palette.primary.main : 'black',
                                    },
                                    display: !matchDownSm ? "block" : "none",
                                }}
                                onClick={() => !!ambassador.linkedInURL ? window.open(ambassador.linkedInURL, "_blank") : null}
                            >
                                {
                                    !!ambassador.linkedInURL && (
                                        <LinkedInIcon sx={{ marginBottom: "-4px", marginRight: "4px", cursor: "pointer" }} />
                                    )
                                }
                                {ambassador.firstName}
                            </Typography>
                        </Stack>
                        <Stack sx={{ display: !matchDownSm ? "none" : "block", marginLeft: 2, textAlign: "left", mt: matchDownSm ? -0.3 : 0 }}>
                            <Typography
                                sx={{
                                    fontSize: matchDownSm ? "1.2em" : "1.3em", fontWeight: 500, cursor: !!ambassador.linkedInURL ? "pointer" : "default", ':hover': {
                                        color: !!ambassador.linkedInURL ? (theme) => theme.palette.primary.main : 'black',
                                    },
                                    cursor: matchDownSm ? "pointer" : "default",
                                }}
                                onClick={() => !!ambassador.linkedInURL ? window.open(ambassador.linkedInURL, "_blank") : null}
                            >
                                {
                                    !!ambassador.linkedInURL && (
                                        <LinkedInIcon sx={{ marginBottom: "-4px", marginRight: "4px", cursor: "pointer" }} />
                                    )
                                }
                                {ambassador.firstName}
                            </Typography>
                            <Tooltip hidden={!ambassador.program} tabIndex={0} title={ambassador.program && !fromMatchMaker ? `Search '${ambassador.program}'` : null} onClick={() => onAddFilter({ type: "Field of Study", value: ambassador.program, id: ambassador.program })}>
                                <Typography
                                    sx={{
                                        fontSize: "1em",
                                        '&:hover': !matchDownSm ? {
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: (theme) => theme.palette.primary.main,
                                            fontWeight: 600,
                                        } : null,
                                    }}
                                >
                                    {ambassador.program ?? ""}
                                </Typography>
                            </Tooltip>
                            {
                                ambassador.homeTown && !hideHomeTown && (
                                    <Tooltip
                                        tabIndex={0}
                                        title={ambassador.homeTown && !fromMatchMaker ? `Filter for '${ambassador.homeTown?.split(',').pop()}'` : null}
                                        placement='top-end'
                                        onClick={ambassador.homeTown ? () => onAddFilter({ id: ambassador.homeTown, type: "Country", value: `${filterFlagEmoji(ambassador.homeTown) ? filterFlagEmoji(ambassador.homeTown) + " " : ""}${ambassador.homeTown?.split(',').pop() || ""}` }) : null}
                                    >
                                        <Typography
                                            sx={{
                                                mt: matchDownSm ? 0.5 : 1,
                                                width: "100%",
                                                color: "black",
                                                textAlign: "left",
                                                marginRight: "auto",
                                                display: 'flex',
                                                flexDirection: 'row',
                                                columnGap: '6px',
                                                alignItems: 'flex-start',
                                                '&:hover': !matchDownSm ? {
                                                    textDecoration: 'underline',
                                                    cursor: 'pointer',
                                                    color: (theme) => theme.palette.primary.main,
                                                    fontWeight: 600,
                                                } : null,
                                                fontSize: matchDownSm ? "0.9em" : "1em",
                                            }}
                                        >
                                            <ReactCountryFlag svg countryCode={getCountryCode(ambassador?.homeTown)} />
                                            <Typography sx={{ lineHeight: '1.1', fontSize: matchDownSm ? "0.9em" : "1em" }}>{removeEmojis(ambassador?.homeTown)}</Typography>
                                        </Typography>
                                    </Tooltip>
                                )
                            }
                        </Stack>
                    </Stack>
                    {
                        matchDownSm && (
                            <IconButton onClick={() => onChatWith(ambassador)} sx={{ marginRight: "-5px", p: 0, marginTop: "-25px", '&:hover': { background: 'transparent' } }}>
                                <SvgIcon sx={{ color: "darkgray", fontSize: "1.5em" }}>
                                    <ArrowForwardIosOutlinedIcon />
                                </SvgIcon>
                            </IconButton>
                        )
                    }
                </Stack>


                <Tooltip hidden={!ambassador.program || matchDownSm} tabIndex={0} title={ambassador.program && !fromMatchMaker ? `Search '${ambassador.program}'` : null} onClick={() => onAddFilter({ type: "Field of Study", value: ambassador.program, id: ambassador.program })}>
                    <Typography
                        sx={{
                            fontSize: "1em",
                            '&:hover': !matchDownSm ? {
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                color: (theme) => theme.palette.primary.main,
                                fontWeight: 600,
                            } : null,
                        }}
                    >
                        {ambassador.program ?? ""}
                    </Typography>
                </Tooltip>
                {
                    !hideMinor && !matchDownSm && (
                        <Typography sx={{ fontSize: "0.9em", color: "darkgray", opacity: ambassador.minor ? 1 : 0 }}>
                            {schoolType === "postgrad" ? "" : "Minor: "}{ambassador.minor}
                        </Typography>
                    )
                }
                {
                    !matchDownSm && (
                        <Button
                            variant='contained'
                            sx={{
                                mt: 2,
                                p: "5%",
                                width: "100%",
                                borderRadius: `${buttonRoundedness}px`,
                                columnGap: "4px",
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                backgroundColor: 'black',
                                fontSize: '1.2em',
                            }}
                            onClick={() => onChatWith(ambassador)}
                        >
                            <div>Chat with</div><div style={{ whiteSpace: 'nowrap' }}>{ambassador.firstName}</div>
                        </Button>
                    )
                }
                <Typography sx={{ display: matchDownSm ? "none" : "block", mt: 2, color: "gray", textAlign: "left", marginRight: "auto", width: "100%" }}>
                    Tags:
                </Typography>
                <Box
                    ref={containerRef}
                    onMouseEnter={stopScrolling}
                    sx={{
                        width: boxWidth,
                        overflowX: "scroll",
                        mt: 1,
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                    <Stack
                        // onMouseLeave={startScrolling}
                        sx={{
                            display: "flex",
                            columnGap: "5px",
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflowX: 'scroll',
                            width: "max-content",
                            flexDirection: 'row',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {(matchDownSm ? [ambassador.ambassadorType, ...ambassador.tags] : ambassador.tags)?.map((tag, index) => (
                            <Tooltip key={tag} title={fromMatchMaker ? "" : `Search '${tag}'`} placement={index % 2 === 0 ? 'top' : 'bottom'} onClick={() => onAddFilter({ type: "Tag", value: tag, id: tag })}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        borderRadius: `${buttonRoundedness}px`,
                                        display: 'inline-flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        backgroundColor: 'black',
                                        fontSize: matchDownSm ? '0.7em' : '0.9em',
                                        fontWeight: 700,
                                        marginTop: matchDownSm ? "5px" : "0px",
                                    }}>
                                    {tag}
                                </Button>
                            </Tooltip>
                        ))}
                    </Stack>
                </Box>
                {
                    ambassador.homeTown && !hideHomeTown && !matchDownSm && (
                        <Typography sx={{ mt: 2, color: "gray", textAlign: "left", marginRight: "auto", width: "100%" }}>
                            Hometown:
                        </Typography>
                    )
                }
                {
                    ambassador.homeTown && !hideHomeTown && !matchDownSm && (
                        <Tooltip
                            tabIndex={0}
                            title={ambassador.homeTown && !fromMatchMaker ? `Filter for '${ambassador.homeTown?.split(',').pop()}'` : null}
                            placement='top-end'
                            onClick={ambassador.homeTown ? () => onAddFilter({ id: ambassador.homeTown, type: "Country", value: `${filterFlagEmoji(ambassador.homeTown) ? filterFlagEmoji(ambassador.homeTown) + " " : ""}${ambassador.homeTown?.split(',').pop() || ""}` }) : null}
                        >
                            <Typography
                                sx={{
                                    mt: 1,
                                    width: "100%",
                                    color: "black",
                                    textAlign: "left",
                                    marginRight: "auto",
                                    display: 'flex',
                                    flexDirection: 'row',
                                    columnGap: '6px',
                                    alignItems: 'flex-start',
                                    '&:hover': !matchDownSm ? {
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        color: (theme) => theme.palette.primary.main,
                                        fontWeight: 600,
                                    } : null,
                                }}
                            >
                                <ReactCountryFlag svg countryCode={getCountryCode(ambassador?.homeTown)} />
                                <Typography sx={{ lineHeight: '1.1' }}>{removeEmojis(ambassador?.homeTown)}</Typography>
                            </Typography>
                        </Tooltip>
                    )
                }
                {/* <Typography sx={{ mt: 2, color: "gray", textAlign: "left", marginRight: "auto" }}>
                    Previous Education:
                </Typography>
                <Typography sx={{ mt: 1, color: "black", textAlign: "left", marginRight: "auto" }}>
                    High School Diploma
                </Typography> */}

                {
                    !hideAboutMe && (
                        <Typography sx={{ mt: matchDownSm ? 1.5 : 2, color: "gray", textAlign: "left", marginRight: "auto", width: "100%" }}>
                            About Me:
                        </Typography>
                    )
                }
                <Box ref={boxRef} sx={{ width: '100%', height: 0, m: 0, p: 0 }} />
                {
                    !!blob && globalAudio && !hideAboutMe && boxWidth > 0 ? (
                        <Tooltip
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [-45, -8],
                                            },
                                        },
                                    ],
                                },
                            }}
                            title={globalAudio.src === audioURL && !globalAudio.paused ? "Stop playing audio" : "Listen to audio"}
                            placement='top'
                        >
                            <Box
                                onMouseEnter={() => {
                                    if (globalAudio.src !== audioURL || globalAudio.paused) {
                                        setAudioColor(schoolColor);
                                    }
                                }}
                                onMouseLeave={() => {
                                    setAudioColor('lightblue');
                                }}
                                sx={{ cursor: 'pointer', position: "relative" }}
                                onClick={() => {
                                    onToggleAudio(audioURL);
                                }}
                            >
                                <Button
                                    tabIndex={0}
                                    // onMouseEnter={() => {
                                    //     setAudioColor(schoolColor);
                                    // }}
                                    // onMouseLeave={() => {
                                    //     setAudioColor('lightblue');
                                    // }}
                                    startIcon={(
                                        <SvgIcon>
                                            {
                                                globalAudio.src === audioURL && !globalAudio.paused ? (<StopCircleOutlined />) : (<PlayCircleOutlineOutlinedIcon />)
                                            }
                                        </SvgIcon>
                                    )} sx={{ position: "absolute", right: 0, top: "-30px" }}>
                                    {globalAudio.src === audioURL && !globalAudio.paused ? "Stop" : "Listen"}
                                </Button>
                                <AudioVisualizer
                                    key={`${boxWidth}-${audioColor}-${ambassador.profileVoiceNoteURL}`}
                                    blob={blob}
                                    width={boxWidth}
                                    height={100}
                                    barWidth={3}
                                    gap={2}
                                    barColor={audioColor}
                                    barPlayedColor={schoolColor}
                                    currentTime={audioCurrentTime}
                                />
                            </Box>
                        </Tooltip>
                    ) : !hideAboutMe ? (
                        <Typography
                            sx={{
                                mt: 1,
                                color: "black",
                                textAlign: "left",
                                marginRight: "auto",
                                height: matchDownSm ? "fit-content" : "100px",
                                overflowY: "scroll",
                                '&::-webkit-scrollbar': {
                                    width: '10px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: showScroll ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,0)',
                                    borderRadius: '20px',
                                    transition: 'backgroundColor .3s ease',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: 'rgba(0,0,0,.2)',
                                },
                            }}
                        >
                            {/* Hello! I am an international Computer Science student. I am a people’s person as I love to meet new people and interact with others daily. I’ve developed a behind-the-lens passion in photography as I’ve been doing it for 1 year. I am all about building bridges and lending a hand whenever I can!! Anyways, I am here to answer any questions you have! */}
                            {ambassador.profileIntroText}
                        </Typography>
                    ) : null
                }

            </Stack>
        </Grid >
    );
}

AmbassadorProfile.propTypes = {
    ambassador: PropTypes.object.isRequired,
    expandedProfile: PropTypes.bool,
    widgetConfig: PropTypes.object,
    isAvailable: PropTypes.bool,
    schoolColor: PropTypes.string,
    schoolType: PropTypes.string,
    globalAudio: PropTypes.object,
    onToggleAudio: PropTypes.func,
    onChatWith: PropTypes.func,
    onAddFilter: PropTypes.func,
    fromMatchMaker: PropTypes.bool,
};

export default AmbassadorProfile;