import PropTypes from 'prop-types';
import { formatDistanceStrict, formatDistanceToNowStrict } from 'date-fns';
import ArchiveIcon from '@untitled-ui/icons-react/build/esm/Archive';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Camera01Icon from '@untitled-ui/icons-react/build/esm/Camera01';
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import PhoneIcon from '@untitled-ui/icons-react/build/esm/Phone';
import SlashCircle01Icon from '@untitled-ui/icons-react/build/esm/SlashCircle01';
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import matchmakerBotIcon from 'src/icons/matchmaker-bot.png';
import aiBotIcon from 'src/icons/ai-bot.png';
import {
    Avatar,
    AvatarGroup,
    Badge,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { usePopover } from 'src/hooks/use-popover';
import { Report } from '@mui/icons-material';
import { isAmbassadorAvailable } from 'src/utils/ambassadors';
import FailSafeAvatar from 'src/pages/components/fail-safe-avatar';


export const ChatThreadToolbar = (props) => {
    const { schoolName, prospectMuted, onMuteToggle, targetAmbassadorID, ambassadors, showToggle, onToggleSideBar, participants = [], ...other } = props;
    const user = useMockedUser();
    const popover = usePopover();
    const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // Maybe use memo for these values

    const isAI = targetAmbassadorID === 'aiChat';
    const isMatchmaker = targetAmbassadorID === 'matchmaker';
    const recipient = ambassadors.byId[targetAmbassadorID];
    const displayName = isAI ? "⚡ School Expert" : isMatchmaker ? "🔮 Ambassador Matchmaker" : recipient?.firstName ?? "Former Ambassador";
    const typeOrSchoolName = isAI ? '(Available 24/7)' : isMatchmaker ? "" : `(${recipient?.ambassadorType ?? schoolName})`;
    const program = isAI || isMatchmaker ? schoolName : recipient?.program ?? '';
    const byLine = (recipient?.ambassadorType ?? schoolName) + (recipient?.program ? ` | ${recipient?.program}` : '');

    const isAvailable = isAI || isMatchmaker ? true : !!recipient ? isAmbassadorAvailable(recipient) : false;

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    flexShrink: 0,
                    minHeight: 64,
                    px: matchDownSm ? 0 : 2,
                    py: matchDownSm ? 0 : 1,
                    width: matchDownSm ? "100%" : "auto",
                }}
                {...other}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                    width={matchDownSm ? "100%" : "auto"}
                >
                    {(showToggle || matchDownSm) && (
                        <Tooltip title="Other threads">
                            <IconButton onClick={onToggleSideBar}>
                                <SvgIcon>
                                    <KeyboardDoubleArrowRightIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                    <AvatarGroup
                        max={2}
                        sx={{ ml: matchDownSm ? "0 !important" : 1 }}
                    // sx={{
                    //     ...(recipients.length > 1 && {
                    //         '& .MuiAvatar-root': {
                    //             height: 30,
                    //             width: 30,
                    //             '&:nth-of-type(2)': {
                    //                 mt: '10px'
                    //             }
                    //         }
                    //     })
                    // }}
                    >
                        {/* {recipients.map((recipient) => ( */}
                        <FailSafeAvatar
                            key={recipient?.id || recipient?.ogID || targetAmbassadorID}
                            profilePicURL={isAI ? aiBotIcon : isMatchmaker ? matchmakerBotIcon : recipient?.photoURL || undefined}
                            cartoonOptions={isAI || isMatchmaker ? null : recipient?.avatarOptions || undefined}
                        />
                        {/* ))} */}
                    </AvatarGroup>
                    <Stack width={matchDownSm ? "100%" : "auto"}>
                        <Stack direction={"row"} alignContent={"center"} alignItems={"center"} justifyContent={"flex-start"} spacing={0}>
                            <Typography textAlign="left" variant="subtitle2" marginTop={matchDownSm ? -1 : 0}>
                                {displayName}
                            </Typography>
                            <Typography display={matchDownSm ? "block" : "none"} textAlign="left" variant="caption" sx={{ color: "text.secondary", marginLeft: "0.5em" }} marginTop={matchDownSm ? -1 : 0}>
                                {typeOrSchoolName}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} alignContent={"center"} alignItems={"center"} justifyContent={"space-between"} spacing={0} display={!matchDownSm ? "none" : "flex"}>
                            <Typography textAlign="left" variant="caption" sx={{ color: "text.secondary" }}>
                                {program}
                            </Typography>
                            <Typography sx={{ marginRight: matchDownSm ? 1 : "2em", fontSize: "0.7em", minWidth: "85px", alignContent: "flex-end", textAlign: "right" }} marginTop={matchDownSm ? 0.5 : "auto"} marginBottom={matchDownSm ? -0.5 : "auto"}>
                                <Badge sx={{ "& .MuiBadge-badge": { backgroundColor: isAvailable ? "#4CBB17" : !recipient ? "darkred" : "#F4BB44", minWidth: "unset !important", width: "11px", height: "12px", marginRight: "13px", top: "-13px" } }} badgeContent=" " overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} />
                                {isAvailable ? "Online Now" : !recipient ? "Inactive" : "Online Soon"}
                            </Typography>
                        </Stack>

                        <Typography
                            textAlign="left"
                            color="text.secondary"
                            variant="caption"
                            sx={{ float: 'left', lineHeight: matchDownSm ? 1.1 : 1.3 }}
                            display={!matchDownSm ? "block" : "none"}
                        >
                            {byLine}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack
                    display={!matchDownSm ? "flex" : "none"}
                    alignItems="center"
                    direction={matchDownSm ? "column" : "row"}
                    spacing={1}
                    sx={{ flexDirection: matchDownSm ? "column-reverse" : "row", minWidth: matchDownSm ? "100px" : "unset", alignItems: "flex-end" }}
                >
                    {/* <IconButton>
                        <SvgIcon>
                            <PhoneIcon />
                        </SvgIcon>
                    </IconButton>
                    <IconButton>
                        <SvgIcon>
                            <Camera01Icon />
                        </SvgIcon>
                    </IconButton> */}
                    <Typography sx={{ marginBottom: "auto", marginRight: matchDownSm ? 0 : "2em", fontSize: "0.7em" }}>
                        <Badge sx={{ "& .MuiBadge-badge": { backgroundColor: isAvailable ? "#4CBB17" : !recipient ? "darkred" : "#F4BB44", minWidth: "unset !important", width: "11px", height: "12px", marginRight: "13px", top: "-13px" } }} badgeContent=" " overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} />
                        {isAvailable ? "Online Now" : !recipient ? "Inactive" : "Online Soon"}
                    </Typography>
                    {
                        !isAI && !isMatchmaker && !matchDownSm && (
                            <Tooltip title="More options">
                                <IconButton
                                    onClick={popover.handleOpen}
                                    ref={popover.anchorRef}
                                    sx={{ m: matchDownSm ? 0 : 'initial', p: matchDownSm ? 0 : 'initial' }}
                                >
                                    <SvgIcon>
                                        <DotsHorizontalIcon />
                                    </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </Stack>
            </Stack>
            {
                !isAI && !isMatchmaker && (
                    <Menu
                        anchorEl={popover.anchorRef.current}
                        keepMounted
                        onClose={popover.handleClose}
                        open={popover.open}
                    >
                        <MenuItem onClick={() => {
                            onMuteToggle();
                            // popover.handleClose();
                        }}>
                            <ListItemIcon>
                                <SvgIcon>
                                    {prospectMuted ? <Bell01Icon /> : <NotificationsOffIcon />}
                                </SvgIcon>
                            </ListItemIcon>
                            <ListItemText primary={prospectMuted ? "Unmute" : "Mute"} />
                        </MenuItem>
                        {/* <MenuItem>
                        <ListItemIcon>
                            <SvgIcon>
                                <SlashCircle01Icon />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Block" />
                    </MenuItem> */}
                        {/* <MenuItem sx={{
                        "&:hover": {
                            color: 'darkred',
                        }
                    }}>
                        <ListItemIcon sx={{ color: 'inherit' }}>
                            <SvgIcon sx={{ color: 'inherit' }}>
                                <Report sx={{ color: 'inherit' }} />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Report & Block" />
                    </MenuItem> */}
                        {/* <MenuItem>
                        <ListItemIcon>
                            <SvgIcon>
                                <ArchiveIcon />
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText primary="Archive" />
                    </MenuItem> */}
                    </Menu>
                )
            }
        </>
    );
};

ChatThreadToolbar.propTypes = {
    schoolName: PropTypes.string,
    targetAmbassadorID: PropTypes.string,
    ambassadors: PropTypes.object,
    participants: PropTypes.array,
    onToggleSideBar: PropTypes.func,
    showToggle: PropTypes.bool,
    prospectMuted: PropTypes.bool,
    onMuteToggle: PropTypes.func,
};
