import { useCallback, lazy, useEffect, useRef, useState, Component, cloneElement } from 'react';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Container,
    Checkbox,
    Divider,
    Link,
    Stack,
    SvgIcon,
    Typography,
    Unstable_Grid2 as Grid,
    useMediaQuery,
    IconButton,
    Tooltip,
    TextField,
    Icon,
    Badge,
    Select,
    MenuItem,
    Tabs,
    Tab,
    Popover,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from "@mui/material/styles";
import { blogApi } from 'src/api/blog';
import { BreadcrumbsSeparator } from 'src/components/breadcrumbs-separator';
import { RouterLink } from 'src/components/router-link';
import { Seo } from 'src/components/seo';
import { useMounted } from 'src/hooks/use-mounted';
import { usePageView } from 'src/hooks/use-page-view';
import { paths } from 'src/paths';
import { PostCard } from 'src/sections/dashboard/blog/post-card';
import { ReactSVG } from 'react-svg';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import StudentIcon from 'src/icons/untitled-ui/duocolor/icon-student.svg';
import StaffIcon from 'src/icons/untitled-ui/duocolor/icon-staff.svg';
import InboxIcon from 'src/icons/untitled-ui/duocolor/icon-inbox.svg';
import SettingsIcon from 'src/icons/untitled-ui/duocolor/icon-settings.svg';
import LogInIcon from 'src/icons/untitled-ui/duocolor/icon-login-02.svg';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AssistantIcon from '@mui/icons-material/Assistant';
import QandA from 'src/icons/untitled-ui/duocolor/icon-question-answer.svg';
import { socialApi } from 'src/api/social';
import { Toaster } from '../../../components/toaster';
import toast from 'react-hot-toast';

import * as Sentry from "@sentry/react";

// Chat related
import { useSearchParams } from 'src/hooks/use-search-params';
import { ChatBlank } from 'src/sections/dashboard/chat/chat-blank';
import { ChatComposer } from 'src/sections/dashboard/chat/chat-composer';
import { ChatContainer } from 'src/sections/dashboard/chat/chat-container';
import { ChatSidebar } from 'src/sections/dashboard/chat/chat-sidebar';
import { ChatThread } from 'src/sections/dashboard/chat/chat-thread';
import { useDispatch } from 'src/store';
import { thunks } from 'src/thunks/chat';
import { analytics, db, firebaseApp } from 'src/libs/firebase';
import { logAnalyticsEvent } from 'src/utils/logging';
import { collection, query, where, onSnapshot, doc, getDoc, addDoc, setDoc, arrayUnion, serverTimestamp, Timestamp, getDocs } from "firebase/firestore";
import { CollectionSearch } from 'src/components/collection-search';
import { useAuth } from 'src/hooks/use-auth';
import { SplashScreen } from 'src/components/splash-screen';
import { ArrowOutwardRounded, PlayCircle, Settings, StopCircleOutlined } from '@mui/icons-material';
import { canadianProvinces, countries, getCountryCode, removeEmojis } from 'src/utils/locations';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { MuiPhone } from 'src/components/mui-phone';
import useSchool from 'src/hooks/use-school';
import { PreMessageFormContent } from 'src/sections/dashboard/chat/chat-pre-message-form';

import LongLogo from 'src/components/logo-long.png';
import { AudioVisualizer } from 'react-audio-visualize';
import { isAmbassadorAvailable } from 'src/utils/ambassadors';
import FailSafeAvatar from 'src/pages/components/fail-safe-avatar';
import { ErrorBoundary } from 'src/components/error';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { keyframes } from '@emotion/react';
import { usesWebKit } from 'src/utils/browser';
import ReactCountryFlag from 'react-country-flag';
import AmbassadorProfile from 'src/pages/components/ambassador-profile';

const functions = getFunctions(firebaseApp);

const FirebaseRegisterPage = lazy(() => import('src/pages/auth/firebase/register'));

function filterFlagEmoji(text) {
    if (!text) {
        return null;
    }
    const flagEmojiPattern = /(?:\uD83C[\uDDE6-\uDDFF]){2}/g;
    return text.match(flagEmojiPattern);
}

const AMBASSADORS_TO_SHOW_INC = 12;

const slideUp = keyframes`
  0% {
    transform: translate3d(0, 5vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const anywhereOption = { type: "Country", value: "🌍 Anywhere", id: "🌍 Anywhere" };

const useSidebar = () => {
    const searchParams = useSearchParams();
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(!matchDownSm);

    // const handleScreenResize = useCallback(() => {
    //     if (!mdUp) {
    //         setOpen(false);
    //     } else {
    //         setOpen(true);
    //     }
    // }, [mdUp]);

    // useEffect(() => {
    //     handleScreenResize();
    // },
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [mdUp]);

    // const handeParamsUpdate = useCallback(() => {
    //     if (!mdUp) {
    //         setOpen(false);
    //     }
    // }, [mdUp]);

    // useEffect(() => {
    //     handeParamsUpdate();
    // },
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [searchParams]);

    const handleToggle = useCallback(() => {
        setOpen((prevState) => !prevState);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    return {
        handleToggle,
        handleClose,
        open
    };
};

const useAmbassadors = (schoolID) => {
    const isMounted = useMounted();
    const [ambassadors, setAmbassadors] = useState({
        byId: {},
        allIds: []
    });

    const handleAmbassadorsGet = useCallback(async () => {
        if (!schoolID) {
            return null;
        }
        try {
            const dbQuery = query(
                collection(db, "users"),
                where("type", "==", "worker"),
                where("company", "==", schoolID),
                where("championActive", "==", true)
            );

            return onSnapshot(dbQuery, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (isMounted()) {
                        if (change.type === "added" || change.type === "modified") {
                            const newAmbassadorData = change.doc.data();
                            if (newAmbassadorData.disableChat) {
                                return;
                            }

                            setAmbassadors((prevState) => {
                                const newAllIds = Array.from(new Set([...prevState.allIds, change.doc.id]));
                                return {
                                    byId: {
                                        ...prevState.byId,
                                        [change.doc.id]: newAmbassadorData
                                    },
                                    allIds: newAllIds
                                };
                            });
                        }
                        if (change.type === "removed") {
                            setAmbassadors((prevState) => {
                                const { [change.doc.id]: _, ...rest } = prevState.byId;
                                return {
                                    byId: rest,
                                    allIds: prevState.allIds.filter((id) => id !== change.doc.id)
                                };
                            });
                        }
                    }
                });
            }, (err) => {
                console.error(err);
                logAnalyticsEvent('error_snapshot_ambassadors', {
                    description: err.message,
                    school: schoolID,
                });
            });
        } catch (err) {
            console.error(err);
            logAnalyticsEvent('error_querying_ambassadors', {
                description: err.message,
                school: schoolID,
            });
        }

        return null;
    }, [isMounted, schoolID]);

    useEffect(() => {
        const unsubscribe = handleAmbassadorsGet();

        return () => {
            // Check if unsubscribe is a function before calling it
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [schoolID]);

    return ambassadors;
};

const useThreads = (userID, schoolID) => {
    const isMounted = useMounted();
    const [threads, setThreads] = useState({
        byId: {},
        allIds: []
    });
    const [initialQueryDone, setInitialQueryDone] = useState(false);

    const handleThreadsGet = useCallback(async () => {
        const currentUserRef = doc(db, "users", userID);
        const currentSchoolRef = doc(db, "schools", schoolID);

        try {
            const dbQuery = query(
                collection(db, "threads"),
                where("prospect", "==", currentUserRef),
                where("school", "==", currentSchoolRef),
                where("report", "==", null),
                where("type", "==", "direct")
            );

            return onSnapshot(dbQuery, async (snapshot) => {
                if (!initialQueryDone) {
                    setInitialQueryDone(true);
                }

                const docChanges = snapshot.docChanges();
                for (const change of docChanges) {
                    if (isMounted()) {
                        if (change.type === "added" || change.type === "modified") {
                            const threadData = change.doc.data();

                            setThreads((prevState) => {
                                const newAllIds = Array.from(new Set([...prevState.allIds, change.doc.id]));
                                return {
                                    byId: {
                                        ...prevState.byId,
                                        [change.doc.id]: threadData
                                    },
                                    allIds: newAllIds
                                };
                            });
                        }
                        if (change.type === "removed") {
                            setThreads((prevState) => {
                                const { [change.doc.id]: _, ...rest } = prevState.byId;
                                return {
                                    byId: rest,
                                    allIds: prevState.allIds.filter((id) => id !== change.doc.id)
                                };
                            });
                        }
                    }
                }
            }, (err) => {
                console.error(err);

                logAnalyticsEvent('error_snapshot_threads', {
                    description: err.message,
                    school: schoolID,
                    user: userID,
                });
            });
        } catch (err) {
            console.error(err);

            logAnalyticsEvent('error_querying_threads', {
                description: err.message,
                school: schoolID,
            });
        }

        return null;
    }, [isMounted]);

    useEffect(() => {
        const unsubscribe = !!userID && !!schoolID ? handleThreadsGet() : null;

        return () => {
            // Check if unsubscribe is a function before calling it
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userID, schoolID]);

    return { threads, initialQueryDone };
};

const InboxView = ({ ambassadors, ogActiveAmbassadorID, onTabChange, onMatchAmbassador, activeSchool, onTurnNotificationsOn, mobileConjoined }) => {
    // Chat related
    const rootRef = useRef(null);
    const searchParams = useSearchParams();
    const compose = searchParams.get('compose') === 'true';
    const sidebar = useSidebar();
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const { user } = useAuth();

    const [activeThreadID, setActiveThreadID] = useState(null);
    const [activeAmbassadorID, setActiveAmbassadorID] = useState(ogActiveAmbassadorID);

    const view = activeThreadID
        ? 'thread'
        : compose
            ? 'compose'
            : 'blank';

    const aiChatThread = {
        id: 'aiChat',
        ambassador: null,
        type: "direct",
        school: doc(db, "schools", activeSchool?.id),
        report: null,
        prospectName: "Me",
        prospect: null,
        prospectMuted: false,
        lastMessageContent: `Hey! I'll try my best to help you with any questions about ${activeSchool?.name}. Ask away!`,
        lastMessageTime: new Date(),
        lastMessageSender: null,
        prospectUnreadCount: 0,
        totalProspectMessages: 0,
    };

    const matchmakerThread = {
        id: 'matchmaker',
        ambassador: null,
        type: "direct",
        school: doc(db, "schools", activeSchool?.id),
        report: null,
        prospectName: "Me",
        prospect: null,
        prospectMuted: false,
        lastMessageContent: "",
        lastMessageTime: new Date(),
        lastMessageSender: null,
        prospectUnreadCount: 0,
        totalProspectMessages: 0,
    };

    const { threads: threadsRaw, initialQueryDone } = useThreads(user?.id, activeSchool?.id);
    // Only show threads where ambassador is not blocked
    let threadsAllIds = threadsRaw.allIds.filter((threadID) => !user?.blockedBy?.includes(threadsRaw.byId[threadID].ambassador?.id));
    let threadsById = Object.fromEntries(
        Object.entries(threadsRaw.byId).filter(([threadID]) => !user?.blockedBy?.includes(threadsRaw.byId[threadID].ambassador?.id))
    );

    if (activeSchool?.enableMatchmakerAI) {
        threadsById = {
            'matchmaker': matchmakerThread,
            ...threadsById
        };
        threadsAllIds = ['matchmaker', ...threadsAllIds];
    }

    const threads = activeSchool?.enablePublicAI ? {
        byId: {
            'aiChat': aiChatThread,
            ...threadsById
        },
        allIds: ['aiChat', ...threadsAllIds]
    } : {
        byId: threadsById,
        allIds: threadsAllIds
    };

    console.log("activeAmbassadorID", activeAmbassadorID);
    const effectiveThreads = ((!activeThreadID || !user) && activeAmbassadorID) ? activeSchool?.enablePublicAI ? {
        byId: {
            'matchmaker': matchmakerThread,
            'aiChat': aiChatThread,
            'dummyThread': {
                id: 'dummyThread',
                ambassador: doc(db, "users", activeAmbassadorID),
                type: "direct",
                school: doc(db, "schools", activeSchool?.id),
                report: null,
                prospectName: "Me",
                prospect: null,
                prospectMuted: false,
                lastMessageContent: ambassadors.byId[activeAmbassadorID]?.profileIntroText || "Hey! Got any questions for me?",
                lastMessageTime: new Date(),
                lastMessageSender: doc(db, "users", activeAmbassadorID),
                prospectUnreadCount: 0,
                totalProspectMessages: 0,
            }
        },
        allIds: [
            'matchmaker',
            'aiChat',
            'dummyThread',
        ]
    } : {
        byId: {
            'matchmaker': matchmakerThread,
            'dummyThread': {
                id: 'dummyThread',
                ambassador: doc(db, "users", activeAmbassadorID),
                type: "direct",
                school: doc(db, "schools", activeSchool?.id),
                report: null,
                prospectName: "Me",
                prospect: null,
                prospectMuted: false,
                lastMessageContent: ambassadors.byId[activeAmbassadorID]?.profileIntroText || "Hey! Got any questions for me?",
                lastMessageTime: new Date(),
                lastMessageSender: doc(db, "users", activeAmbassadorID),
                prospectUnreadCount: 0,
                totalProspectMessages: 0,
            }
        },
        allIds: [
            'matchmaker',
            'dummyThread',
        ]
    } : threads;

    const prospectMuted = effectiveThreads.byId[activeThreadID]?.prospectMuted;

    useEffect(() => {
        console.log("Spooks B activeAmbassadorID activeThreadID", activeAmbassadorID);
        if (activeAmbassadorID) {
            if (activeAmbassadorID === 'aiChat') {
                setActiveThreadID('aiChat');
            } else if (activeAmbassadorID === 'matchmaker') {
                setActiveThreadID('matchmaker');
            } else {
                const foundID = effectiveThreads.allIds.find((threadID) => effectiveThreads.byId[threadID].ambassador?.id === activeAmbassadorID);

                if (foundID) {
                    setActiveThreadID(foundID);
                }
            }
        }
    }, [activeAmbassadorID, effectiveThreads, activeSchool]);

    const onChatWith = (ambassador) => {
        // Find the ambassador ID
        const ambassadorID = ambassadors.allIds.find((id) => ambassadors.byId[id].ogID === ambassador.ogID);
        setActiveAmbassadorID(ambassadorID);

        const foundID = effectiveThreads.allIds.find((threadID) => effectiveThreads.byId[threadID].ambassador?.id === ambassadorID);
        if (foundID) {
            setActiveThreadID(foundID);
        } else {
            setActiveThreadID(null);
        }
    };

    return (
        <Box
            ref={rootRef}
            sx={{
                bottom: 0,
                display: 'flex',
                left: 0,
                position: 'relative',
                right: 0,
                top: mdUp ? 0 : matchDownSm ? 10 : 20,
                maxWidth: "1000px",
                maxHeight: !matchDownSm ? "70vh" : "unset",
                height: mobileConjoined ? "calc(100vh + 55px)" : matchDownSm ? "calc(100vh - 75px)" : "unset",
            }}
        >
            <ChatSidebar
                container={rootRef.current}
                onClose={sidebar.handleClose}
                open={sidebar.open && (threads.length > 0 || mdUp || matchDownSm)}
                threads={effectiveThreads}
                ambassadors={ambassadors}
                activeAmbassadorID={activeAmbassadorID}
                onSelectThread={(threadId) => {
                    // Find the ambassador associated with this thread
                    const thread = effectiveThreads.byId[threadId];
                    const ambassadorID = thread.id === 'aiChat' ? 'aiChat' : thread.id === 'matchmaker' ? 'matchmaker' : thread.ambassador?.id;
                    setActiveAmbassadorID(ambassadorID);
                }}
                onToggleSideBar={sidebar.handleToggle}
            />
            <ChatContainer open={sidebar.open}>
                {view === 'thread' && <ChatThread showToggle={!sidebar.open} prospectMuted={prospectMuted} onToggleSideBar={sidebar.handleToggle} activeAmbassadorID={activeAmbassadorID} ambassadors={ambassadors} onChatWith={onChatWith} onTabChange={onTabChange} activeThreadID={activeThreadID} activeThread={effectiveThreads.byId[activeThreadID]} activeSchool={activeSchool} onTurnNotificationsOn={onTurnNotificationsOn} onAiChat={() => setActiveAmbassadorID('aiChat')} onMatchmaker={() => setActiveAmbassadorID('matchmaker')} />}
                {view === 'compose' && <ChatComposer />}
                {view === 'blank' && <ChatBlank publicAIEnabled={activeSchool?.enablePublicAI} matchMakerEnabled={activeSchool?.enableMatchmakerAI} onTabChange={onTabChange} onAiChat={() => setActiveAmbassadorID('aiChat')} onMatchmaker={() => setActiveAmbassadorID('matchmaker')} onMatchAmbassador={onMatchAmbassador} />}
            </ChatContainer>
        </Box>
    );
};

const SettingsView = ({ scrollEnd, mobileConjoined }) => {
    const { user, school, schoolSpecificUserInfo, deleteUser, signOut } = useAuth();

    const scrollBox = useRef(null);

    const agreedToMarketing = user?.agreedToMarketing?.includes(school?.id) || false;
    const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false);
    const [selectedReason, setSelectedReason] = useState(null);

    const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const [originalUser, setOriginalUser] = useState(schoolSpecificUserInfo ?? {
        aiChat: null,
        alreadyApplied: null,
        plannedEnrollment: null,
        selectedPrograms: [],
        selectedCountryOrProvince: null,
    });

    const countriesAndProvinces = [
        ...countries.map((country) => ({ type: "International", value: country, id: country })),
        ...canadianProvinces.map((province) => ({ type: "Canada", value: province, id: province })),
    ];

    const reasonsToDeleteAccount = [
        "I'm not interested in the program / school anymore",
        "I found the platform difficult to use / buggy",
        "I found the platform unhelpful",
        "Ambassadors were unresponsive / unprofessional",
        "I found the platform annoying / spammy",
        "Privacy / data usage concerns",
    ];
    const reasonsToDeleteAccountObjects = reasonsToDeleteAccount.map((reason) => ({ type: "Reason", value: reason, id: reason }));

    const marketingEmailsPermissions = `Join the email list to stay informed about recruitment insights and news from ${school?.name}!`;

    const defaultCountryOrProvince = !!schoolSpecificUserInfo?.selectedCountryOrProvince ? { type: (schoolSpecificUserInfo.selectedCountryOrProvince.includes("🇨🇦") ? "Canada" : "International"), value: schoolSpecificUserInfo.selectedCountryOrProvince, id: schoolSpecificUserInfo.selectedCountryOrProvince } : { type: "International", value: "🌍 Not Specified", id: "Global" };

    const setUserDetail = async (key, value) => {
        const userDoc = doc(db, "users", user.id);
        setDoc(userDoc, {
            [key]: value,
        }, { merge: true });
    };

    const setProspectDetail = async (key, value) => {
        const prospectDoc = doc(db, "schools", school.id, "prospects", user.id);
        setDoc(prospectDoc, {
            [key]: value,
        }, { merge: true });
    };

    const setAgreedToMarketing = async (value) => {
        const userDoc = doc(db, "users", user.id);
        setDoc(userDoc, {
            agreedToMarketing: value ? arrayUnion(school?.id ?? "INVALID_SCHOOL") : [],
        }, { merge: true });

        toast.success('Subscription updated');
    };

    useEffect(() => {
        if (scrollEnd) {
            // Scroll to the bottom
            if (scrollBox.current) {
                scrollBox.current.scrollTop = scrollBox.current.scrollHeight;
            }
        }
    }, [scrollEnd]);

    return (
        <Box
            ref={scrollBox}
            sx={{
                overflowY: "scroll",
                overflowX: "hidden",
                paddingTop: "20px",
                maxHeight: "805px",
                height: mobileConjoined ? "calc(100vh + 55px)" : matchDownSm ? "calc(100vh - 75px)" : "unset",
                pl: "10px",
                mb: 3,
                maxWidth: "600px",
                '&::-webkit-scrollbar': {
                    width: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    borderRadius: '20px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: 'rgba(0,0,0,.2)',
                },
            }}
        >
            <Dialog
                open={showDeletionConfirmation}
                onClose={() => setShowDeletionConfirmation(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        // Add selected reason to the deletionReasons array in the school document
                        const deletionReasonCollection = collection(db, "schools", school?.id, "deletionReasons");
                        addDoc(deletionReasonCollection, {
                            reason: selectedReason,
                            time: new Date(),
                            email: user.email, // TODO determine if this is compliant with data privacy policy
                        });

                        logAnalyticsEvent('user_deleted', {
                            email: user.email,
                            reason: selectedReason,
                            school: school?.id,
                        });

                        // Delete the user from Firebase Auth (will automatically be deleted from Firestore)
                        deleteUser();
                    },
                }}
            >
                <DialogTitle variant='h6'>This action cannot be undone. This will permanently delete your account and all associated data.</DialogTitle>
                <DialogContent sx={{ textAlign: "-webkit-center" }}>
                    <Typography variant='body1' fontWeight='light' textAlign='center' mb={2}>
                        We'd appreciate it if you could give us some feedback on why you're deleting your account.
                    </Typography>
                    <CollectionSearch initialFilters={{ tags: [] }} options={reasonsToDeleteAccountObjects} onFiltersChange={(filters) => setSelectedReason(filters.tags)} label={`Select all that apply`} />
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{ color: "gray" }}
                        onClick={() => {
                            setShowDeletionConfirmation(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        size='large'
                        color='error'
                    >
                        Confirm Deletion
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2} pr={2}>
                <Typography variant="h4" align='left' textAlign="left" fontWeight="light" width="100%" mb={matchDownSm ? 1 : 3} mt={matchDownSm ? 1 : 0} ml="20px">
                    Personal Details
                </Typography>
                <Grid item="true" xs={12} textAlign="left" maxWidth={450}>
                    <TextField
                        defaultValue={user.email}
                        label="Email"
                        sx={{ flexGrow: 1, width: "100%" }}
                        disabled
                    />
                </Grid>
                <Grid item="true" xs={12} textAlign="left" maxWidth={450}>
                    <TextField
                        defaultValue={user.name}
                        label="Name"
                        sx={{ flexGrow: 1, width: "100%" }}
                        onChange={(e) => setUserDetail("name", e.target.value)}
                        onBlur={(e) => toast.success('Name updated')}
                    />
                </Grid>
                <Grid item="true" xs={12} textAlign="left" maxWidth={450}>
                    <CollectionSearch initialFilters={{ tags: defaultCountryOrProvince }} options={countriesAndProvinces} onFiltersChange={(filters) => {
                        setProspectDetail("selectedCountryOrProvince", filters.tags.value);
                        toast.success('Country / region updated');
                    }} label={`Country / region of origin`} multiple={false} />
                </Grid>
                <Grid item="true" xs={12} textAlign="left" maxWidth={450}>
                    <MuiPhone
                        value={user.phone || ""}
                        onBlur={(phone) => {
                            if (phone.length > 8) {
                                setUserDetail("phone", phone)
                                toast.success('Phone number updated');
                            } else {
                                toast.error('Invalid phone number');
                            }
                        }}
                    />
                </Grid>

                {
                    school?.type === "postgrad" ? (
                        <Stack direction="row" spacing={2} mt={2} justifyContent={"center"} pl={2}>
                            <Button sx={{ flexGrow: 1 }} variant={!user.interestedInPartTime ? "contained" : "outlined"} onClick={() => setUserDetail("interestedInPartTime", true)}>Interested in Part-Time</Button>
                            <Button sx={{ flexGrow: 1 }} variant={!!user.interestedInPartTime ? "contained" : "outlined"} onClick={() => setUserDetail("interestedInPartTime", false)}>Interested in Full-Time</Button>
                        </Stack>
                    ) : (
                        <Stack direction="row" spacing={2} mt={2} justifyContent={"center"} pl={2}>
                            <Button sx={{ flexGrow: 1 }} variant={!user.isParentRelative ? "contained" : "outlined"} onClick={() => setUserDetail("isParentRelative", false)}>I am a Student</Button>
                            <Button sx={{ flexGrow: 1 }} variant={!!user.isParentRelative ? "contained" : "outlined"} onClick={() => setUserDetail("isParentRelative", true)}>I am a Parent / Relative of a Student</Button>
                        </Stack>
                    )
                }


                <Typography variant="h4" align='left' textAlign="left" fontWeight="light" mt={4} mb={2} width="100%" ml="20px">
                    Application Information
                </Typography>
                <PreMessageFormContent originalUser={originalUser} activeSchool={school} user={user} isSettingsPage={true} />

                <Typography variant="h4" align='left' textAlign="left" fontWeight="light" mt={3} mb={3} width="100%" ml="20px">
                    Notification Preferences
                </Typography>
                <Typography variant="body1" align='left' textAlign="left" fontWeight={500} mb={2} ml="20px">
                    How would you like to receive notifications?
                </Typography>
                <Grid container spacing={2} maxWidth="450px" sx={{ float: "left", marginLeft: "20px", pr: 2 }}>
                    <Grid item="true" xs={12}>
                        <Button fullWidth variant="outlined" color='error' onClick={() => {
                            setUserDetail("notificationPreference", "none");
                            toast.success('Notification preference updated');
                        }}>Do not notify me when they respond</Button>
                    </Grid>
                    <Grid item="true" xs={6}>
                        <Button fullWidth variant={user.notificationPreference === "SMS" ? "contained" : "outlined"} onClick={() => {
                            setUserDetail("notificationPreference", "SMS");
                            toast.success('Notification preference updated');
                        }}>SMS Only</Button>
                    </Grid>
                    <Grid item="true" xs={6}>
                        <Button fullWidth variant={user.notificationPreference === "email" || !user.notificationPreference ? "contained" : "outlined"} onClick={() => {
                            setUserDetail("notificationPreference", "email");
                            toast.success('Notification preference updated');
                        }}>Email Only</Button>
                    </Grid>
                    <Grid item="true" xs={12}>
                        <Button fullWidth variant={user.notificationPreference === "both" ? "contained" : "outlined"} onClick={() => {
                            setUserDetail("notificationPreference", "both");
                            toast.success('Notification preference updated');
                        }}>Both Email and SMS</Button>
                    </Grid>
                </Grid>
                <Stack display="inline-flex" spacing={1.5} direction="row" mt={3} sx={{ alignItems: "flex-start", maxWidth: "450px", float: "left" }} ml="0px">
                    <Checkbox onChange={(e) => setAgreedToMarketing(e.target.checked)} checked={agreedToMarketing} sx={{ pt: 0, pr: 0, ml: 1 }} inputProps={{ 'aria-label': marketingEmailsPermissions }} />
                    <Typography variant="body1" color="text.secondary" textAlign="left">
                        {marketingEmailsPermissions}
                    </Typography>
                </Stack>
                <Typography variant="h4" align='left' textAlign="left" fontWeight="light" mt={3} mb={3} width="100%" ml="20px">
                    Account Management
                </Typography>
                <Button
                    sx={{ maxWidth: "450px", mr: 4, marginLeft: "20px" }}
                    variant={"outlined"}
                    onClick={() => {
                        signOut();
                    }}
                    color='primary'
                >
                    Sign Out
                </Button>
                <Button
                    sx={{ maxWidth: "450px" }}
                    variant={"outlined"}
                    onClick={() => {
                        setShowDeletionConfirmation(true);
                    }}
                    color='error'
                >
                    Delete Account
                </Button>
            </Grid>
        </Box>
    );
};

const Page = () => {
    const { user, dispatchUser, isSignInWithEmailLink, signInWithEmailLink } = useAuth();
    const { school, slug: schoolSlug, tab: initialTab } = useSchool();

    const ambassadorsRaw = useAmbassadors(school?.id);
    // Only show ambassadors that are not blocked
    const ambassadorsAllIds = ambassadorsRaw.allIds.filter((ambassadorID) => !user?.blockedBy?.includes(ambassadorID) && ambassadorID.includes("ambassador_"));
    const ambassadorsById = Object.fromEntries(
        Object.entries(ambassadorsRaw.byId).filter(([ambassadorID]) => !user?.blockedBy?.includes(ambassadorID))
    );
    const ambassadors = { byId: ambassadorsById, allIds: ambassadorsAllIds };

    const [loading, setLoading] = useState(false);

    const [mobileConjoined, setMobileConjoined] = useState(null);
    const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
    const [filtersOpenType, setFiltersOpenType] = useState(null);
    const filterPopOverOpen = Boolean(filtersAnchorEl);
    const filerPopOverId = filterPopOverOpen ? 'simple-popover' : undefined;

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const [tab, setTab] = useState(matchDownSm ? 'inbox' : 'students');
    const [activeAmbassadorID, setActiveAmbassadorID] = useState(null);

    const [settingsScrollEnd, setSettingsScrollEnd] = useState(false);

    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [filteredTags, setFilteredTags] = useState([]);
    const [filteredRegion, setFilteredRegion] = useState(anywhereOption.value);

    const [maxAmbassadorsToShow, setMaxAmbassadorsToShow] = useState(AMBASSADORS_TO_SHOW_INC);
    const [activeAudioURL, setActiveAudioURL] = useState(null);
    const [expandedProfile, setExpandedProfile] = useState(null);

    const studyRef = useRef();
    const countryRef = useRef();
    const tagsRef = useRef();

    const boundingRef = useRef(null);

    const globalAudio = useRef(new Audio()).current;

    usePageView();

    const handleChatWith = (chatWithAmbassador) => {
        console.log("Spooks A chatWithAmbassador", chatWithAmbassador);
        setTab('inbox');
        // Get the ambassador ID
        const ambassadorID = Object.keys(ambassadors.byId).find((id) => ambassadors.byId[id].ogID === chatWithAmbassador.ogID);
        setActiveAmbassadorID(ambassadorID);

        logAnalyticsEvent('chat_with_ambassador', { school: school?.id || schoolSlug, ambassador: ambassadorID });
    };

    // Get the field of study options
    const fieldOfStudyArr = Object.keys(ambassadors.byId).map((ambassadorID) => ambassadors.byId[ambassadorID].program);
    const uniqueFieldOfStudyArr = Array.from(new Set(fieldOfStudyArr));
    const uniqueFieldOfStudyOptions = uniqueFieldOfStudyArr.map((fieldOfStudy) => ({ type: "Field of Study", value: fieldOfStudy, id: fieldOfStudy }));

    // Get the country options
    const countryArr = Object.keys(ambassadors.byId).map((ambassadorID) => `${filterFlagEmoji(ambassadors.byId[ambassadorID].homeTown) ? filterFlagEmoji(ambassadors.byId[ambassadorID].homeTown) + " " : ""}${ambassadors.byId[ambassadorID].homeTown?.split(',').pop() || ""}`);
    const uniqueCountryArr = Array.from(new Set(countryArr));
    const uniqueCountryOptions = uniqueCountryArr.map((country) => ({ type: "Country", value: country, id: country }));
    const uniqueCountryOptionsWithAnywhere = [anywhereOption, ...uniqueCountryOptions];

    // Get the tags options
    const tagsArr = Object.keys(ambassadors.byId).map((ambassadorID) => ambassadors.byId[ambassadorID].tags);
    const uniqueTagsArr = Array.from(new Set(tagsArr.flat()));
    const uniqueTagsOptions = uniqueTagsArr.map((tag) => ({ type: "Tag", value: tag, id: tag }));

    // Get the ambassador type options
    const ambassadorTypeArr = Object.keys(ambassadors.byId).map((ambassadorID) => ambassadors.byId[ambassadorID].ambassadorType);
    const uniqueAmbassadorTypeArr = Array.from(new Set(ambassadorTypeArr));
    const uniqueAmbassadorTypeOptions = uniqueAmbassadorTypeArr.map((ambassadorType) => ({ type: "Tag", value: ambassadorType, id: ambassadorType }));

    const matchesPrograms = (ambassador) => !filteredPrograms.length || filteredPrograms.some((program) => ambassador.program === program.value);
    const matchesTags = (ambassador) => (!filteredTags || !filteredTags.length || filteredTags.some((tag) => ambassador.tags?.includes(tag.value) || ambassador.ambassadorType === tag.value));
    const matchesRegion = (ambassador) => filterFlagEmoji(filteredRegion) === filterFlagEmoji(anywhereOption.value) || ambassador.homeTown?.includes(filterFlagEmoji(filteredRegion));
    const matchesAmbassadorType = (ambassador) => (tab === "students" && (ambassador.ambassadorType !== "Staff / Faculty Member" && ambassador.ambassadorType !== "Instructor / Professor") || matchDownSm) || (tab === "staff" && (ambassador.ambassadorType === "Staff / Faculty Member" || ambassador.ambassadorType === "Instructor / Professor"));

    const filteredAmbassadors = ambassadors.allIds.map((ambassadorID) => ambassadors.byId[ambassadorID]).filter((ambassador) => matchesTags(ambassador) && matchesRegion(ambassador) && matchesPrograms(ambassador) && matchesAmbassadorType(ambassador) && !ambassador.disableChat); // Still show ambassadors that are never available for chats - the school can assign the incoming messages to another ambassador

    const addTag = (tag) => {
        if (tag.type === "Field of Study") {
            if (!filteredTags.some((t) => t.value === tag.value)) {
                studyRef.current?.addTag(tag);
            }
        }
        if (tag.type === "Country") {
            if (filteredRegion !== tag.value) {
                countryRef.current?.addTag(tag);
            }
        }
        if (tag.type === "Tag") {
            if (!filteredTags.some((t) => t.value === tag.value)) {
                tagsRef.current?.addTag(tag);
            }
        }
    };

    const handleAmbassadorError = (error, info) => {
        console.error(error);

        Sentry.captureException(error);
        logAnalyticsEvent('error', {
            type: 'ambassadorError',
            description: error?.message,
            info: info?.componentStack,
            school: school?.id || schoolSlug,
        });

        // setTab('error');
    };

    const handleError = (error, info, itemID = null) => {
        console.error(error);
        console.log("HANDLE ERROR TRIGGERED", error, info, itemID);

        Sentry.captureException(error);
        logAnalyticsEvent('error', {
            type: 'tabError',
            description: error?.message,
            info: info?.componentStack,
            school: school?.id || schoolSlug,
        });

        setTab('error');
    };

    const handleTabChange = ({ whichTab }) => {
        setTab(whichTab);

        logAnalyticsEvent('tab_change', { school: school?.id || schoolSlug, tab: whichTab });
    };

    const handleMatchAmbassador = () => {
        // Next, see if there are any available ambassadors
        const availableAmbassadors = ambassadors.allIds.filter((ambassadorID) => isAmbassadorAvailable(ambassadors.byId[ambassadorID]));
        if (availableAmbassadors.length) {
            // If there are available ambassadors, chat with a random one
            const randomAmbassador = availableAmbassadors[Math.floor(Math.random() * availableAmbassadors.length)];
            console.log('randomAmbassador', randomAmbassador);
            setActiveAmbassadorID(randomAmbassador);
        } else if (ambassadors.allIds.length) {
            // If there are no available ambassadors, match with a random ambassador
            const randomAmbassador = ambassadors.allIds[Math.floor(Math.random() * ambassadors.allIds.length)];
            if (randomAmbassador) {
                setActiveAmbassadorID(randomAmbassador);
            }
        }
        setTab('inbox');
    };

    const handleAudioToggle = (audioURL) => {
        globalAudio.currentTime = 0;
        if (activeAudioURL === audioURL) {
            if (globalAudio.paused) {
                globalAudio.play();
            } else {
                globalAudio.pause();
            }
        } else {
            setActiveAudioURL(audioURL);
            globalAudio.src = audioURL;
            globalAudio.load();
            globalAudio.play();
        }
    };

    useEffect(() => {
        if (tab === 'students') {
            if (filteredTags.some((tag) => tag.value === "Staff / Faculty Member" || tag.value === "Instructor / Professor")) {
                tagsRef.current?.clear();
            }
        } else if (tab === 'staff') {
            tagsRef.current?.clear();
            tagsRef.current?.addTag({ type: "Tag", value: "Staff / Faculty Member", id: "Staff / Faculty Member" }, false);
            tagsRef.current?.addTag({ type: "Tag", value: "Instructor / Professor", id: "Instructor / Professor" }, false);
        } else {
            if (!!user && !user.docLoaded) {
                // Check if the user doc exists and create it if not via Firestore
                setLoading(true);
                const userRef = doc(db, "users", user.id);
                getDoc(userRef)
                    .then((userDoc) => {
                        if (userDoc.exists()) {
                            // Check if current school is listed in their schools
                            // If not, add it
                            const userData = userDoc.data();
                            if (!userData.schools.includes(school?.id)) {
                                const updatedSchools = arrayUnion(school?.id ?? "INVALID_SCHOOL");
                                setDoc(userRef, { schools: updatedSchools }, { merge: true });
                            }

                            dispatchUser({
                                ...user,
                                docLoaded: true,
                            }, userData);
                            setLoading(false);
                        } else {
                            const userData = {
                                email: user.email,
                                name: user.name || user.email.split('@')[0],
                                schools: arrayUnion(school?.id ?? "INVALID_SCHOOL"),
                                type: "prospect",
                                created: new Date(),
                            };
                            setDoc(userRef, userData)
                                .then(() => {
                                    dispatchUser({
                                        ...user,
                                        docLoaded: true,
                                    }, userData);
                                    setLoading(false);
                                })
                                .catch((error) => {
                                    console.error(error);
                                    Sentry.captureException(error);

                                    setLoading(false);
                                });
                        }
                    });
            }
        }
    }, [tab, dispatchUser, user]);

    useEffect(() => {
        logAnalyticsEvent('programsFilter', { school: school?.id || schoolSlug, programs: filteredPrograms.map((program) => program.value) });
    }, [filteredPrograms]);

    useEffect(() => {
        if (filteredTags.some((tag) => tag.value === "Staff / Faculty Member" || tag.value === "Instructor / Professor") && tab !== "staff" && !matchDownSm) {
            setTab('staff');
        } else if (tab === "staff" && !filteredTags.some((tag) => tag.value === "Staff / Faculty Member" || tag.value === "Instructor / Professor")) {
            setTab('students');
        }

        logAnalyticsEvent('tagsFilter', { school: school?.id || schoolSlug, tags: filteredTags.map((tag) => tag.value) });
    }, [filteredTags]);

    useEffect(() => {
        logAnalyticsEvent('regionFilter', { school: school?.id || schoolSlug, region: filteredRegion });
    }, [filteredRegion]);

    useEffect(() => {
        const savedEmail = window.localStorage.getItem('emailForSignIn');

        if (!!savedEmail && !user && isSignInWithEmailLink()) {
            signInWithEmailLink(savedEmail, window.location.href);
            logAnalyticsEvent('email_link_sign_in', { school: school?.id || schoolSlug, email: savedEmail });
        } else if (!user && isSignInWithEmailLink()) {
            setTab('settings');
        }
    }, [window.location.href]);

    useEffect(() => {
        if (school) {
            logAnalyticsEvent('widget_loaded', { school: school?.id || schoolSlug });

            if (initialTab) {
                if (initialTab === 'inbox' || initialTab === 'settings' || initialTab === 'students' || initialTab === 'staff' || initialTab === 'login') {
                    setTab(initialTab === 'login' ? 'settings' : initialTab);
                }
            }
        }
    }, [school, initialTab]);

    useEffect(() => {
        function generateUUID() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        const recordVisit = () => {
            let visitorId = localStorage.getItem('visitorId');
            if (visitorId) {
                // If visitorId exists, we can assume that the user has visited the site before
                return;
            }

            visitorId = generateUUID();
            localStorage.setItem('visitorId', visitorId);

            const visitorDoc = doc(db, "schools", school?.id, "visitors", visitorId);
            setDoc(visitorDoc, {
                firstVisit: new Date(),
            });
        };

        if (school) {
            recordVisit();
        }
    }, [school]);

    useEffect(() => {
        if (tab !== 'settings') {
            setSettingsScrollEnd(false);
        }
    }, [tab]);

    useEffect(() => {
        console.log("PARENT HEIGHT", window.st);
        window.parent.postMessage({ type: 'CT_CWRZ' }, '*');
    }, []);

    useEffect(() => {
        window.addEventListener('message', (event) => {
            // Widget is in view
            if (event.data.type === 'CT_WIV') {
                console.log("Widget in view (from Widget)");
            }

            // Widget is out of view
            if (event.data.type === 'CT_WOV') {
                console.log("Widget out of view (from Widget)");
            }

            // Window is small
            if (event.data.type === 'CT_WSM') {
                console.log("Window is small (from Widget)", event.data);
                setMobileConjoined(true);
            }

            // Window is large
            if (event.data.type === 'CT_WLG') {
                console.log("Window is large (from Widget)", event.data);
                setMobileConjoined(false);
            }
        });
    }, []);

    const mobileConjoinedMarginTop = mobileConjoined ? "-65px" : 0;

    return loading || !school ? <SplashScreen /> : (
        <Box sx={{ maxWidth: "1000px", placeSelf: "center", transform: mobileConjoined ? "scale(0.8)" : "unset", marginTop: mobileConjoinedMarginTop }} >
            <Seo title={`Chat with ${school ? school.name : "current"} students and staff now`} />
            <Box
                ref={boundingRef}
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 0,
                    transform: matchDownSm ? "scale(0.95)" : "scale(0.9)",
                }}
            >
                <Container maxWidth="xl" sx={{ textAlign: "-webkit-center", padding: 0, margin: 0 }}>
                    <Typography align='center' fontSize={matchDownSm ? "1.3em" : "2em"} marginTop={matchDownSm ? -1 : 0}>
                        Chat with {school && !matchDownSm ? school.name : "our"} students and staff now.
                    </Typography>
                    {
                        matchDownSm ? (
                            <Tabs value={tab} onChange={(_, value) => setTab(value)} aria-label="tabs" variant="fullWidth" TabIndicatorProps={{ style: { display: "none" } }} sx={{ minWidth: "400px", maxWidth: "90%" }}>
                                <Tab label="People" value="students" sx={{ fontSize: "1.2em", height: "50px", minHeight: "unset !important", width: "100%", m: "0 !important", borderBottomStyle: "solid", borderBottomWidth: tab === "students" ? "2px" : "1px", borderBottomColor: "inherit", pt: "0 !important", paddingBottom: 0 }} iconPosition="start" icon={
                                    <ReactSVG
                                        src={StudentIcon}
                                        alt="Talk to our people"
                                        beforeInjection={(svg) => {
                                            const strokeColor = "currentColor";
                                            svg.setAttribute('style', `max-width: 28px; max-height: 28px; stroke: ${strokeColor}; stroke-width: 0px`)
                                        }}
                                        style={{ height: "28px" }}
                                    />
                                } />
                                <Tab label="Inbox" value="inbox" sx={{ fontSize: "1.2em", height: "50px", minHeight: "unset !important", width: "100%", m: "0 !important", borderBottomStyle: "solid", borderBottomWidth: tab === 'inbox' ? "2px" : "1px", borderBottomColor: "inherit", pt: "0 !important", paddingBottom: 0 }} iconPosition="start" icon={
                                    <ReactSVG
                                        src={InboxIcon}
                                        alt="Check your inbox"
                                        beforeInjection={(svg) => {
                                            const strokeColor = "currentColor";
                                            const fillColor = "currentColor";
                                            svg.setAttribute('style', `max-width: 28px; max-height: 28px; stroke: ${strokeColor}; stroke-width: 0px; fill: ${fillColor}`)
                                        }}
                                        style={{ height: "28px" }}
                                    />
                                } />
                                <Tab label={!!user ? "Settings" : "Login"} value="settings" sx={{ fontSize: "1.2em", height: "50px", minHeight: "unset !important", width: "100%", m: "0 !important", borderBottomStyle: "solid", borderBottomWidth: tab === "settings" ? "2px" : "1px", borderBottomColor: "inherit", pt: "0 !important", paddingBottom: 0 }} iconPosition="start" icon={
                                    <ReactSVG
                                        src={!!user ? SettingsIcon : LogInIcon}
                                        alt="Settings"
                                        beforeInjection={(svg) => {
                                            const strokeColor = "currentColor";
                                            const fillColor = "currentColor";
                                            svg.setAttribute('style', `max-width: 28px; max-height: 28px; stroke: ${strokeColor}; stroke-width: 0px; fill: ${!!user ? fillColor : 'none'}`)
                                        }}
                                        style={{ height: "28px" }}
                                    />
                                } />
                            </Tabs>
                        ) : (
                            <Grid container direction="row" justifyContent="space-between" mt="10px" maxWidth="750px" sx={{ transform: "scale(0.9)" }}>
                                <Grid
                                    item="true"
                                    textAlign="center"
                                    xs={2.4}
                                    display="grid"
                                    borderRadius="30%"
                                    color={tab === "students" ? "white" : "black"}
                                    sx={{
                                        backgroundColor: tab === 'students' ? "black" : "transparent",
                                        cursor: "pointer",
                                        transition: "all .3s ease",
                                        WebkitTransition: "all .3s ease",
                                        MozTransition: "all .3s ease",
                                        '&:active': {
                                            opacity: tab === 'students' ? 1 : 0.5
                                        },
                                    }}
                                >
                                    <Button
                                        sx={{ padding: 0, py: "3%", border: 'none', background: 'none', color: "unset", display: "block" }}
                                        onClick={() => setTab('students')}
                                    >
                                        <ReactSVG
                                            src={StudentIcon}
                                            alt="Talk to students"
                                            beforeInjection={(svg) => {
                                                const strokeColor = tab === 'students' ? "white" : "black";
                                                if (matchUpMd) {
                                                    svg.setAttribute('style', `max-width: 55px; max-height: 55px; stroke: ${strokeColor}; stroke-width: 0px`)
                                                } else {
                                                    svg.setAttribute('style', `max-width: 42px; max-height: 42px; stroke: ${strokeColor}; stroke-width: 0px`)
                                                }
                                            }}
                                            style={{ height: matchUpMd ? "65px" : "52px" }}
                                        />
                                        <Typography fontSize={matchUpMd ? "24px" : "13px"} fontWeight={500} align='center' alignSelf="end">
                                            Students
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid
                                    item="true"
                                    textAlign="center"
                                    xs={2.4}
                                    display="grid"
                                    py="3%"
                                    borderRadius="30%"
                                    color={tab === "staff" ? "white" : "black"}
                                    sx={{
                                        backgroundColor: tab === 'staff' ? "black" : "transparent",
                                        cursor: "pointer",
                                        transition: "all .3s ease",
                                        WebkitTransition: "all .3s ease",
                                        MozTransition: "all .3s ease",
                                        '&:active': {
                                            opacity: tab === 'staff' ? 1 : 0.5
                                        }
                                    }}
                                >
                                    <Button
                                        sx={{ padding: 0, py: "3%", border: 'none', background: 'none', color: "unset", display: "block" }}
                                        onClick={() => setTab('staff')}
                                    >
                                        <ReactSVG
                                            src={StaffIcon}
                                            alt="Talk to staff"
                                            beforeInjection={(svg) => {
                                                const strokeColor = tab === 'staff' ? "white" : "black";
                                                const fillColor = tab === 'staff' ? "white" : "black";
                                                if (matchUpMd) {
                                                    svg.setAttribute('style', `max-width: 50px; max-height: 50px; stroke: ${strokeColor}; stroke-width: 0px; fill: ${fillColor}`)
                                                } else {
                                                    svg.setAttribute('style', `max-width: 40px; max-height: 40px; stroke: ${strokeColor}; stroke-width: 0px; fill: ${fillColor}`)
                                                }
                                            }}
                                            style={{ height: matchUpMd ? "65px" : "52px" }}
                                        />
                                        <Typography fontSize={matchUpMd ? "24px" : "13px"} fontWeight={500} align='center' alignSelf="end">
                                            Staff
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid
                                    item="true"
                                    textAlign="center"
                                    xs={2.4}
                                    display="grid"
                                    py="3%"
                                    borderRadius="30%"
                                    color={tab === 'inbox' ? "white" : "black"}
                                    sx={{
                                        backgroundColor: tab === 'inbox' ? "black" : "transparent",
                                        cursor: "pointer",
                                        transition: "all .3s ease",
                                        WebkitTransition: "all .3s ease",
                                        MozTransition: "all .3s ease",
                                        '&:active': {
                                            opacity: tab === 'inbox' ? 1 : 0.5
                                        }
                                    }}
                                >
                                    <Button
                                        sx={{ padding: 0, py: "3%", border: 'none', background: 'none', color: "unset", display: "block" }}
                                        onClick={() => setTab('inbox')}
                                    >
                                        <ReactSVG
                                            src={InboxIcon}
                                            alt="Check inbox"
                                            beforeInjection={(svg) => {
                                                const strokeColor = tab === 'inbox' ? "white" : "black";
                                                const fillColor = tab === 'inbox' ? "white" : "black";
                                                if (matchUpMd) {
                                                    svg.setAttribute('style', `max-width: 50px; max-height: 50px; stroke: ${strokeColor}; stroke-width: 0px; fill: ${fillColor}`)
                                                } else {
                                                    svg.setAttribute('style', `max-width: 40px; max-height: 40px; stroke: ${strokeColor}; stroke-width: 0px; fill: ${fillColor}`)
                                                }
                                            }}
                                            style={{ height: matchUpMd ? "65px" : "52px" }}
                                        />
                                        <Typography fontSize={matchUpMd ? "24px" : "13px"} fontWeight={500} align='center' alignSelf="end">
                                            Inbox
                                        </Typography>
                                    </Button>
                                </Grid>
                                {/* <Grid
                                item="true"
                                textAlign="center"
                                xs={2.4}
                                display="grid"
                                py="3%"
                                borderRadius="30%"
                                color={tab === "qa" ? "white" : "black"}
                                sx={{
                                    backgroundColor: tab === 'qa' ? "black" : "transparent",
                                    cursor: "pointer",
                                    transition: "all .3s ease",
                                    WebkitTransition: "all .3s ease",
                                    MozTransition: "all .3s ease",
                                    '&:active': {
                                        opacity: tab === 'students' ? 1 : 0.5
                                    }
                                }}
                                onClick={() => setTab('qa')}
                            >
                                <ReactSVG
                                    src={QandA}
                                    alt="Frequentaly asked questions and answers"
                                    beforeInjection={(svg) => {
                                        const strokeColor = tab === 'qa' ? "white" : "black";
                                        if (matchUpMd) {
                                            svg.setAttribute('style', `max-width: 50px; max-height: 50px; stroke: ${strokeColor}; stroke-width: 0px`)
                                        } else {
                                            svg.setAttribute('style', `max-width: 35px; max-height: 35px; stroke: ${strokeColor}; stroke-width: 0px`)
                                        }
                                    }}
                                    style={{ height: matchUpMd ? "65px" : "52px" }}
                                />
                                <Typography fontSize={matchUpMd ? "24px" : "13px"} fontWeight={500} align='center' alignSelf="end">
                                    Events
                                </Typography>
                            </Grid> */}
                                <Grid
                                    item="true"
                                    textAlign="center"
                                    xs={2.4}
                                    display="grid"
                                    py="3%"
                                    borderRadius="30%"
                                    color={tab === "settings" ? "white" : "black"}
                                    sx={{
                                        backgroundColor: tab === 'settings' ? "black" : "transparent",
                                        cursor: "pointer",
                                        transition: "all .3s ease",
                                        WebkitTransition: "all .3s ease",
                                        MozTransition: "all .3s ease",
                                        '&:active': {
                                            opacity: tab === 'settings' ? 1 : 0.5
                                        }
                                    }}
                                    onClick={() => setTab('settings')}
                                >
                                    <Button
                                        sx={{ padding: 0, py: "3%", border: 'none', background: 'none', color: "unset", display: "block" }}
                                        onClick={() => setTab('settings')}
                                    >
                                        <ReactSVG
                                            src={!!user ? SettingsIcon : LogInIcon}
                                            alt="Settings"
                                            beforeInjection={(svg) => {
                                                const strokeColor = tab === 'settings' ? "stroke: white;" : "stroke: black;";
                                                const fillColor = tab === 'settings' ? "fill: white;" : "fill: black;";
                                                if (matchUpMd) {
                                                    svg.setAttribute('style', `max-width: 54px; max-height: 54px; ${!!user ? strokeColor : ""} stroke-width: 0px; ${!!user ? fillColor : ""}`)
                                                } else {
                                                    svg.setAttribute('style', `max-width: 37px; max-height: 37px; ${!!user ? strokeColor : ""} stroke-width: 0px; ${!!user ? fillColor : ""}`)
                                                }
                                            }}
                                            style={{ height: matchUpMd ? "65px" : "52px" }}
                                        />
                                        <Typography fontSize={matchUpMd ? "24px" : "13px"} fontWeight={500} align='center' alignSelf="end">
                                            {!!user ? "Settings" : "Sign Up"}
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        !matchDownSm && (
                            <Divider sx={{ my: 2, display: !matchUpMd && tab === 'inbox' ? "none" : "block" }} />
                        )
                    }
                    {
                        matchDownSm ? (
                            <Stack direction="row" sx={{ pb: 3, pt: 2, px: 1, columnGap: "7px", alignItems: "center" }} display={tab == "students" || tab === "staff" ? "flex" : "none"}>
                                <Typography fontSize="13px">
                                    Filters:
                                </Typography>
                                <Stack direction="row" justifyContent="space-between" columnGap="7px">
                                    <Button variant={filteredPrograms.length > 0 ? 'contained' : 'outlined'} sx={{ width: "110px", height: "30px", fontSize: "12px" }} onClick={(event) => { setFiltersAnchorEl(event.currentTarget); setFiltersOpenType("Program") }}>Program</Button>
                                    <Button variant={filteredRegion !== anywhereOption.value ? 'contained' : 'outlined'} sx={{ width: "105px", px: 2, height: "30px", fontSize: "12px" }} onClick={(event) => { setFiltersAnchorEl(event.currentTarget); setFiltersOpenType("Country") }}>Country</Button>
                                    <Button variant={filteredTags.length ? 'contained' : 'outlined'} sx={{ width: "105px", px: 2, height: "30px", fontSize: "12px" }} onClick={(event) => { setFiltersAnchorEl(event.currentTarget); setFiltersOpenType("Tags") }}>Tags</Button>
                                </Stack>
                                <Popover id={filerPopOverId} open={filterPopOverOpen} anchorEl={filtersAnchorEl} onClose={() => setFiltersAnchorEl(null) && setFiltersOpenType(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} PaperProps={{ sx: { width: "85%", p: 0, pt: 2 } }}>
                                    <Stack direction={matchUpMd ? "row" : "column"} mb={2} spacing={2} pl="3%" pr="3%" display={tab == "students" || tab === "staff" ? "flex" : "none"} maxWidth="900px" width="100%">
                                        {
                                            filtersOpenType === "Program" && (
                                                <CollectionSearch ref={studyRef} initialFilters={{ tags: filteredPrograms }} options={uniqueFieldOfStudyOptions} onFiltersChange={(filters) => setFilteredPrograms(filters.tags)} label={`Program / Field (${filteredPrograms.length} Selected)`} fixedHeight />
                                            )
                                        }
                                        {
                                            filtersOpenType === "Country" && (
                                                <CollectionSearch ref={countryRef} initialFilters={{ tags: { type: "Country", value: filteredRegion, id: filteredRegion } }} options={uniqueCountryOptionsWithAnywhere} onFiltersChange={(filters) => setFilteredRegion(filters.tags.value)} label="Country / Region" multiple={false} />
                                            )
                                        }
                                        {
                                            filtersOpenType === "Tags" && (
                                                <CollectionSearch ref={tagsRef} initialFilters={{ tags: filteredTags }} options={[...uniqueAmbassadorTypeOptions, ...uniqueTagsOptions]} onFiltersChange={(filters) => setFilteredTags(filters.tags)} label={`Tags (${filteredTags.length} Selected)`} fixStaff={true} fixedHeight />
                                            )
                                        }
                                    </Stack>
                                </Popover>
                            </Stack>
                        ) : (
                            <Stack direction={matchUpMd ? "row" : "column"} mb={2} spacing={2} pl="3%" pr="3%" display={tab == "students" || tab === "staff" ? "flex" : "none"} maxWidth="900px">
                                <CollectionSearch ref={studyRef} initialFilters={{ tags: [] }} options={uniqueFieldOfStudyOptions} onFiltersChange={(filters) => setFilteredPrograms(filters.tags)} label={`Program / Field (${filteredPrograms.length} Selected)`} fixedHeight />
                                <CollectionSearch ref={countryRef} initialFilters={{ tags: anywhereOption }} options={uniqueCountryOptionsWithAnywhere} onFiltersChange={(filters) => setFilteredRegion(filters.tags.value)} label="Country / Region" multiple={false} />
                                <CollectionSearch ref={tagsRef} initialFilters={{ tags: [] }} options={[...uniqueAmbassadorTypeOptions, ...uniqueTagsOptions]} onFiltersChange={(filters) => setFilteredTags(filters.tags)} label={`Tags (${filteredTags.length} Selected)`} fixStaff={true} fixedHeight />
                            </Stack>
                        )
                    }
                    <Toaster />
                    {(tab === 'students' || tab === 'staff') && (
                        <ErrorBoundary onError={handleError} schoolID={school?.id} itemID={school?.id}>
                            <Box sx={{
                                maxHeight: "770px",
                                height: mobileConjoined ? "100vh" : matchDownSm ? "calc(100vh - 133px)" : "unset",
                                maxWidth: "1000px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                paddingBottom: matchDownSm ? "0" : "2.5em",
                                paddingLeft: 0,
                                paddingRight: 0,
                                paddingTop: 0,
                                '&::-webkit-scrollbar': {
                                    width: '10px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.1)',
                                    borderRadius: '20px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    backgroundColor: 'rgba(0,0,0,.2)',
                                },
                                transform: matchDownSm ? null : "scale(0.9)",
                                marginTop: matchDownSm ? "-10px" : "-50px",
                            }}>
                                <Grid
                                    container
                                >
                                    {filteredAmbassadors.sort((ambassador) => {
                                        // Show available ambassadors first
                                        return isAmbassadorAvailable(ambassador) ? -1 : 1;
                                    }).map((ambassador, index) => index < maxAmbassadorsToShow ? (
                                        <ErrorBoundary key={ambassador.ogID} isAmbassadorError={true} onError={handleAmbassadorError} schoolID={school?.id}>
                                            <AmbassadorProfile key={ambassador.ogID} expandedProfile={expandedProfile} isAvailable={isAmbassadorAvailable(ambassador)} schoolColor={school?.primaryColor} widgetConfig={school?.widgetConfig || {}} schoolType={school?.type} globalAudio={globalAudio} onToggleAudio={handleAudioToggle} ambassador={ambassador} onChatWith={handleChatWith} onAddFilter={matchDownSm ? () => { } : (ambassadorFilter) => addTag(ambassadorFilter)} />
                                        </ErrorBoundary>
                                    ) : null)}
                                </Grid>
                                <Stack
                                    alignItems="center"
                                    direction="row"
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{
                                        mt: 4,
                                        mb: 4,
                                        display: (maxAmbassadorsToShow >= filteredAmbassadors.length && matchDownSm) ? "none" : "block"
                                    }}
                                >
                                    <Button
                                        size='large'
                                        // endIcon={(
                                        //     <SvgIcon>
                                        //         <ArrowRightIcon />
                                        //     </SvgIcon>
                                        // )}
                                        sx={{ display: maxAmbassadorsToShow >= filteredAmbassadors.length ? "none" : "block" }}
                                        onClick={() => setMaxAmbassadorsToShow(maxAmbassadorsToShow + AMBASSADORS_TO_SHOW_INC)}
                                    >
                                        Load more ambassadors
                                    </Button>
                                </Stack>
                            </Box>
                        </ErrorBoundary>
                    )}
                    {tab === 'inbox' && (
                        <ErrorBoundary onError={handleError} schoolID={school?.id} itemID={school?.id}>
                            <InboxView ambassadors={ambassadors} ogActiveAmbassadorID={activeAmbassadorID} onTabChange={handleTabChange} onMatchAmbassador={handleMatchAmbassador} activeSchool={school} onTurnNotificationsOn={() => {
                                setTab('settings');
                                setSettingsScrollEnd(true);
                            }} mobileConjoined={mobileConjoined} />
                        </ErrorBoundary>
                    )}
                    {tab === 'settings' && (
                        <ErrorBoundary onError={handleError} schoolID={school?.id} itemID={school?.id}>
                            {!!user ? (
                                <SettingsView scrollEnd={settingsScrollEnd} mobileConjoined={mobileConjoined} />
                            ) : (
                                <FirebaseRegisterPage />
                            )}
                        </ErrorBoundary>
                    )}
                    {tab === 'error' && (
                        <>
                            <Typography variant="h4" align='center' fontWeight="light">
                                An error occurred. Please try again.
                            </Typography>
                            <Button sx={{ mt: 4 }} variant='contained' size='large' onClick={() => window.location.reload()}>
                                Reload
                            </Button>
                        </>
                    )}
                    {
                        (tab === "students" || tab === "staff") && !matchDownSm && (
                            <Stack justifyContent="center" spacing={matchUpMd ? 1.5 : 0.5} direction={matchUpMd ? "row" : "column"} sx={{ /*border: "1px solid darkgray", borderRadius: 1, px: 2,*/ alignItems: "center", mb: 5, mt: -2 }}>
                                <Typography
                                    color="black"
                                    variant="subtitle1"
                                    mb={matchUpMd ? 0 : 1}
                                >
                                    Don't know where to start?
                                </Typography>

                                <Button
                                    onClick={() => {
                                        handleMatchAmbassador();
                                    }}
                                    startIcon={(
                                        <SvgIcon>
                                            <ConnectWithoutContactIcon />
                                        </SvgIcon>
                                    )}
                                    variant="outlined"
                                    sx={{
                                        // py: 0.6,
                                        // backgroundColor: 'black',
                                    }}
                                >
                                    Match Me with an Ambassador
                                </Button>
                                {
                                    school?.enablePublicAI && (
                                        <>
                                            <Typography
                                                color="text.secondary"
                                                variant="subtitle1"
                                            >
                                                OR
                                            </Typography>
                                            <Button
                                                onClick={() => {
                                                    setActiveAmbassadorID('aiChat');
                                                    setTab('inbox');
                                                }}
                                                startIcon={(
                                                    <SvgIcon>
                                                        <AssistantIcon />
                                                    </SvgIcon>
                                                )}
                                                variant="outlined"
                                            // sx={{ backgroundColor: 'black' }}
                                            >
                                                Get Instant Answers from our AI Bot
                                            </Button>
                                        </>
                                    )
                                }
                            </Stack>
                        )
                    }
                </Container>
            </Box>
            <Typography sx={{ display: matchDownSm ? "none" : "block", transform: "scale(0.8)", textAlign: "right", m: "5%", mt: (tab === 'inbox' || tab === 'settings') ? "-10px" : filteredAmbassadors.length > 0 ? "-80px" : "-40px" }}>
                Powered By <img src={LongLogo} alt="CampusThreads Logo" style={{ height: "23px", verticalAlign: "middle", marginLeft: "10px" }} />
            </Typography>
            <Box height="15px" sx={{ height: "15px", minHeight: "15px", display: matchDownSm ? "none" : "block" }} />
        </Box>
    );
};

export default Page;
