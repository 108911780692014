import PropTypes from 'prop-types';
import { Avatar, Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { ChatMessage } from './chat-message';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useAuth } from 'src/hooks/use-auth';
import FailSafeAvatar from 'src/pages/components/fail-safe-avatar';
import aiBotIcon from 'src/icons/ai-bot.png';
import matchmakerBotIcon from 'src/icons/matchmaker-bot.png';

const getAuthor = (message, ambassadors, userID, photoURL) => {
    // Since chat mock db is not synced with external auth providers
    // we set the user details from user auth state instead of thread participants
    if (message.authorId === userID) {
        return {
            name: 'Me',
            avatar: photoURL,
            avatarOptions: null,
            isUser: true
        };
    }

    if (message.authorId === 'aiChat') {
        return {
            name: 'AI Bot',
            avatar: aiBotIcon,
            isUser: false,
            avatarOptions: null,
        };
    }

    if (message.authorId === 'matchmaker') {
        return {
            name: 'Matchmaker Bot',
            avatar: matchmakerBotIcon,
            isUser: false,
            avatarOptions: null,
        };
    }

    const ambassador = ambassadors.byId[message.authorId];

    if (!ambassador) {
        return {
            name: 'Former Ambassador',
            avatar: '',
            isUser: false,
            avatarOptions: null,
        };
    }

    return {
        avatarOptions: ambassador.avatarOptions,
        avatar: ambassador.photoURL,
        name: ambassador.firstName,
        isUser: false
    };
};

export const ChatMessages = (props) => {
    const { onTabChange, onChatWith, onTurnNotificationsOn, isAiChat, isMatchmaker, onAiChat, onMatchmaker, onEdit, onReply, messages, ambassadors, ambassadorName, activeThreadID, contributorsAddedDates, ...other } = props;
    const { user } = useAuth();
    const anonUserID = localStorage.getItem('visitorId');

    const sortedMessages = messages.sort((a, b) => a.createdAt - b.createdAt);
    const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // Keep track of which "contributor added to chat" messages have been shown
    // via a map with keys the same as contributorsAddedDates and boolean values
    // indicating whether the message has been shown
    const contributorsAddedMessagesShown = Object.keys(contributorsAddedDates ?? {}).reduce((acc, key) => {
        acc[key] = null;
        return acc;
    }, {}) ?? {};

    const messagesReverseSorted = sortedMessages.slice().reverse();

    for (let i = 0; i < messagesReverseSorted.length; i++) {
        const message = messagesReverseSorted[i];
        const contributorsAddedToChat = Object.keys(contributorsAddedDates ?? {}).filter((contributorId) => {
            if (contributorsAddedMessagesShown[contributorId]) {
                return false;
            }

            if (contributorsAddedDates[contributorId].toMillis() > message.createdAt) {
                return true;
            }
        });

        contributorsAddedToChat.forEach((contributorId) => {
            contributorsAddedMessagesShown[contributorId] = message.id;
        });
    }

    return (
        <Stack
            spacing={2}
            sx={{ p: 3 }}
            {...other}>
            {sortedMessages.map((message) => {
                const author = getAuthor(message, ambassadors, user?.id ?? anonUserID, user?.photoURL);

                const replyMessage = !!message.replyMessage ? messages.find((m) => m.id === message.replyMessage.id) : null;
                const replyMessageSender = replyMessage ? getAuthor(replyMessage, ambassadors, user?.id ?? anonUserID, user?.photoURL) : null;

                return (
                    <Stack key={message.id} rowGap={1}>
                        <ChatMessage
                            id={message.id}
                            queryID={message.queryID}
                            isAiChat={isAiChat}
                            isMatchmaker={isMatchmaker}
                            onAiChat={onAiChat}
                            onMatchmaker={onMatchmaker}
                            onTabChange={onTabChange}
                            activeThreadID={activeThreadID}
                            onReply={onReply}
                            onEdit={onEdit}
                            authorName={author.name}
                            body={message.body}
                            contentType={message.contentType}
                            createdAt={message.createdAt}
                            position={author.isUser ? 'right' : 'left'}
                            replyMessageSender={replyMessageSender?.name ?? null}
                            replyMessageContent={replyMessage?.body ?? null}
                            reactions={message.reactions}
                            helpful={message.helpful}
                            matchedAmbassador={message.matchedAmbassador}
                            ambassadorName={ambassadorName}
                            isEdited={message.isEdited}
                            adminEdited={message.adminEdited}
                            isUser={author.isUser}
                            avatar={author.avatar}
                            onTurnNotificationsOn={onTurnNotificationsOn}
                            cartoonOptions={author.avatarOptions}
                            ambassadors={isMatchmaker ? ambassadors : null}
                            onChatWith={onChatWith}
                        />
                        <Stack>
                            {Object.keys(contributorsAddedMessagesShown).filter((contributorId) => contributorsAddedMessagesShown[contributorId] === message.id).map((contributorId, idx) => {
                                const contributor = ambassadors.byId[contributorId];

                                if (!contributor) {
                                    return null;
                                }

                                return (
                                    <Box key={`contributor_added_${message.id}_${contributorId}`} sx={{ mb: 1, display: "flex", flexDirection: "row", textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>
                                        <FailSafeAvatar
                                            profilePicURL={contributor?.photoURL}
                                            sz={25}
                                            sx={{ marginRight: 1 }}
                                            cartoonOptions={contributor?.avatarOptions}
                                        />
                                        <Typography
                                            color="text.secondary"
                                            sx={{ display: "block" }}
                                            variant="subtitle2"
                                        >
                                            {contributor?.firstName} was added to the chat
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Stack>
                    </Stack>
                );
            })}
        </Stack>
    );
};

ChatMessages.propTypes = {
    onTabChange: PropTypes.func,
    onChatWith: PropTypes.func,
    onTurnNotificationsOn: PropTypes.func,
    isAiChat: PropTypes.bool,
    isMatchmaker: PropTypes.bool,
    onAiChat: PropTypes.func,
    onMatchmaker: PropTypes.func,
    activeThreadID: PropTypes.string,
    onReply: PropTypes.func,
    onEdit: PropTypes.func,
    messages: PropTypes.array,
    ambassadors: PropTypes.object,
    ambassadorName: PropTypes.string,
    contributorsAddedDates: PropTypes.object,
};
