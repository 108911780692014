export function getCountryCode(text) {
    if (!text) {
        return null;
    }
    const flagEmojiPattern = /(?:\uD83C[\uDDE6-\uDDFF]){2}/g;
    const countryEmoji = text.match(flagEmojiPattern);

    if (countryEmoji) {
        const codePoints = countryEmoji[0].codePointAt(0).toString(16) + ' ' + countryEmoji[0].codePointAt(2).toString(16);
        const countryCode = String.fromCodePoint(
            parseInt(codePoints.split(' ')[0], 16) - 0x1F1E6 + 0x41,
            parseInt(codePoints.split(' ')[1], 16) - 0x1F1E6 + 0x41
        );
        return countryCode;
    }
    return null;
}

export function removeEmojis(text) {
    if (!text) {
        return "";
    }
    return text.replace(/[\p{Emoji_Presentation}\p{Extended_Pictographic}]/gu, '');
}

export const countries = [
    "🇦🇫 Afghanistan",
    "🇦🇱 Albania",
    "🇩🇿 Algeria",
    "🇦🇩 Andorra",
    "🇦🇴 Angola",
    "🇦🇬 Antigua and Barbuda",
    "🇦🇷 Argentina",
    "🇦🇲 Armenia",
    "🇦🇺 Australia",
    "🇦🇹 Austria",
    "🇦🇿 Azerbaijan",
    "🇧🇸 The Bahamas",
    "🇧🇭 Bahrain",
    "🇧🇩 Bangladesh",
    "🇧🇧 Barbados",
    "🇧🇾 Belarus",
    "🇧🇪 Belgium",
    "🇧🇿 Belize",
    "🇧🇯 Benin",
    "🇧🇹 Bhutan",
    "🇧🇴 Bolivia",
    "🇧🇦 Bosnia and Herzegovina",
    "🇧🇼 Botswana",
    "🇧🇷 Brazil",
    "🇧🇳 Brunei",
    "🇧🇬 Bulgaria",
    "🇧🇫 Burkina Faso",
    "🇧🇮 Burundi",
    "🇨🇻 Cabo Verde",
    "🇰🇭 Cambodia",
    "🇨🇲 Cameroon",
    "🇨🇦 Canada",
    "🇨🇫 Central African Republic",
    "🇹🇩 Chad",
    "🇨🇱 Chile",
    "🇨🇳 China",
    "🇨🇴 Colombia",
    "🇰🇲 Comoros",
    "🇨🇬 Congo",
    "🇨🇷 Costa Rica",
    "🇭🇷 Croatia",
    "🇨🇺 Cuba",
    "🇨🇾 Cyprus",
    "🇨🇿 Czechia",
    "🇩🇰 Denmark",
    "🇩🇯 Djibouti",
    "🇩🇲 Dominica",
    "🇩🇴 Dominican Republic",
    "🇹🇱 East Timor",
    "🇪🇨 Ecuador",
    "🇪🇬 Egypt",
    "🇸🇻 El Salvador",
    "🇬🇶 Equatorial Guinea",
    "🇪🇷 Eritrea",
    "🇪🇪 Estonia",
    "🇸🇿 Eswatini",
    "🇪🇹 Ethiopia",
    "🇫🇯 Fiji",
    "🇫🇮 Finland",
    "🇫🇷 France",
    "🇬🇦 Gabon",
    "🇬🇲 Gambia",
    "🇬🇪 Georgia",
    "🇩🇪 Germany",
    "🇬🇭 Ghana",
    "🇬🇷 Greece",
    "🇬🇩 Grenada",
    "🇬🇹 Guatemala",
    "🇬🇳 Guinea",
    "🇬🇼 Guinea-Bissau",
    "🇬🇾 Guyana",
    "🇭🇹 Haiti",
    "🇭🇳 Honduras",
    "🇭🇺 Hungary",
    "🇮🇸 Iceland",
    "🇮🇳 India",
    "🇮🇩 Indonesia",
    "🇮🇷 Iran",
    "🇮🇶 Iraq",
    "🇮🇪 Ireland",
    "🇮🇱 Israel",
    "🇮🇹 Italy",
    "🇨🇮 Ivory Coast",
    "🇯🇲 Jamaica",
    "🇯🇵 Japan",
    "🇯🇴 Jordan",
    "🇰🇿 Kazakhstan",
    "🇰🇪 Kenya",
    "🇰🇮 Kiribati",
    "🇰🇵 North Korea",
    "🇰🇷 South Korea",
    "🇽🇰 Kosovo",
    "🇰🇼 Kuwait",
    "🇰🇬 Kyrgyzstan",
    "🇱🇦 Laos",
    "🇱🇻 Latvia",
    "🇱🇧 Lebanon",
    "🇱🇸 Lesotho",
    "🇱🇷 Liberia",
    "🇱🇾 Libya",
    "🇱🇮 Liechtenstein",
    "🇱🇹 Lithuania",
    "🇱🇺 Luxembourg",
    "🇲🇬 Madagascar",
    "🇲🇼 Malawi",
    "🇲🇾 Malaysia",
    "🇲🇻 Maldives",
    "🇲🇱 Mali",
    "🇲🇹 Malta",
    "🇲🇭 Marshall Islands",
    "🇲🇷 Mauritania",
    "🇲🇺 Mauritius",
    "🇲🇽 Mexico",
    "🇫🇲 Micronesia",
    "🇲🇩 Moldova",
    "🇲🇨 Monaco",
    "🇲🇳 Mongolia",
    "🇲🇪 Montenegro",
    "🇲🇦 Morocco",
    "🇲🇿 Mozambique",
    "🇲🇲 Myanmar",
    "🇳🇦 Namibia",
    "🇳🇷 Nauru",
    "🇳🇵 Nepal",
    "🇳🇱 Netherlands",
    "🇳🇿 New Zealand",
    "🇳🇮 Nicaragua",
    "🇳🇪 Niger",
    "🇳🇬 Nigeria",
    "🇲🇰 North Macedonia",
    "🇳🇴 Norway",
    "🇴🇲 Oman",
    "🇵🇰 Pakistan",
    "🇵🇼 Palau",
    "🇵🇸 Palestine",
    "🇵🇦 Panama",
    "🇵🇬 Papua New Guinea",
    "🇵🇾 Paraguay",
    "🇵🇪 Peru",
    "🇵🇭 Philippines",
    "🇵🇱 Poland",
    "🇵🇹 Portugal",
    "🇶🇦 Qatar",
    "🇷🇴 Romania",
    "🇷🇺 Russia",
    "🇷🇼 Rwanda",
    "🇰🇳 Saint Kitts and Nevis",
    "🇱🇨 Saint Lucia",
    "🇻🇨 Saint Vincent and the Grenadines",
    "🇼🇸 Samoa",
    "🇸🇲 San Marino",
    "🇸🇹 Sao Tome and Principe",
    "🇸🇦 Saudi Arabia",
    "🇸🇳 Senegal",
    "🇷🇸 Serbia",
    "🇸🇨 Seychelles",
    "🇸🇱 Sierra Leone",
    "🇸🇬 Singapore",
    "🇸🇰 Slovakia",
    "🇸🇮 Slovenia",
    "🇸🇧 Solomon Islands",
    "🇸🇴 Somalia",
    "🇿🇦 South Africa",
    "🇸🇸 South Sudan",
    "🇪🇸 Spain",
    "🇱🇰 Sri Lanka",
    "🇸🇩 Sudan",
    "🇸🇷 Suriname",
    "🇸🇪 Sweden",
    "🇨🇭 Switzerland",
    "🇸🇾 Syria",
    "🇹🇼 Taiwan",
    "🇹🇯 Tajikistan",
    "🇹🇿 Tanzania",
    "🇹🇭 Thailand",
    "🇹🇬 Togo",
    "🇹🇴 Tonga",
    "🇹🇹 Trinidad and Tobago",
    "🇹🇳 Tunisia",
    "🇹🇷 Türkiye",
    "🇹🇲 Turkmenistan",
    "🇹🇻 Tuvalu",
    "🇺🇬 Uganda",
    "🇺🇦 Ukraine",
    "🇦🇪 United Arab Emirates (UAE)",
    "🇬🇧 United Kingdom (UK)",
    "🇺🇸 United States of America (USA)",
    "🇺🇾 Uruguay",
    "🇺🇿 Uzbekistan",
    "🇻🇺 Vanuatu",
    "🇻🇦 Vatican City",
    "🇻🇪 Venezuela",
    "🇻🇳 Vietnam",
    "🇾🇪 Yemen",
    "🇿🇲 Zambia",
    "🇿🇼 Zimbabwe",
];

export const canadianProvinces = [
    "🇨🇦 Alberta, Canada",
    "🇨🇦 British Columbia, Canada",
    "🇨🇦 Manitoba, Canada",
    "🇨🇦 New Brunswick, Canada",
    "🇨🇦 Newfoundland and Labrador, Canada",
    "🇨🇦 Nova Scotia, Canada",
    "🇨🇦 Ontario, Canada",
    "🇨🇦 Prince Edward Island, Canada",
    "🇨🇦 Quebec, Canada",
    "🇨🇦 Saskatchewan, Canada",
    "🇨🇦 Northwest Territories, Canada",
    "🇨🇦 Nunavut, Canada",
    "🇨🇦 Yukon, Canada",
];
