import { Box, Button, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AssistantIcon from '@mui/icons-material/Assistant';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import PropTypes from 'prop-types';
import MessageDotsSquare from '@untitled-ui/icons-react/build/esm/MessageDotsSquare';


export const ChatBlank = ({ publicAIEnabled, matchMakerEnabled, onTabChange, onAiChat, onMatchmaker }) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            paddingBottom: 20,
            marginTop: 10,
        }}
    >
        <Typography
            color="text.secondary"
            sx={{ my: 2 }}
            variant="subtitle1"
        >
            Choose an option below to get started
        </Typography>
        {/* <Box
            // component="img"
            // src="/assets/errors/error-404.png"
            sx={{
                height: 'auto',
                maxWidth: 120
            }}
        >
            <SvgIcon sx={{ height: 40, width: 40, color: "#b4b4b4", mb: 2.5 }}>
                <MessageDotsSquare />
            </SvgIcon>
        </Box> */}
        <Button
            onClick={() => onTabChange({
                whichTab: 'students',
            })}
            startIcon={(
                <SvgIcon sx={{ fontSize: "25px !important" }}>
                    <PersonSearchIcon />
                </SvgIcon>
            )}
            variant="contained"
            sx={{ backgroundColor: 'black', fontSize: '18px !important' }}
        >
            Browse List of Ambassadors
        </Button>
        {
            matchMakerEnabled && (
                <>
                    <Typography
                        color="text.secondary"
                        sx={{ my: 1 }}
                        variant="subtitle1"
                    >
                        OR
                    </Typography>
                    <Button
                        onClick={() => onMatchmaker()}
                        startIcon={(
                            <SvgIcon sx={{ fontSize: "25px !important" }}>
                                <ConnectWithoutContactIcon />
                            </SvgIcon>
                        )}
                        variant="contained"
                        sx={{ backgroundColor: 'black', fontSize: '18px !important' }}
                    >
                        Match Me with an Ambassador
                    </Button>
                </>
            )
        }
        {
            publicAIEnabled && (
                <>
                    <Typography
                        color="text.secondary"
                        sx={{ my: 1 }}
                        variant="subtitle1"
                    >
                        OR
                    </Typography>
                    <Button
                        onClick={() => onAiChat()}
                        startIcon={(
                            <SvgIcon sx={{ fontSize: "25px !important" }}>
                                <AssistantIcon />
                            </SvgIcon>
                        )}
                        variant="contained"
                        sx={{ backgroundColor: 'black', fontSize: '18px !important' }}
                    >
                        Get Instant Answers from Our Bot
                    </Button>
                </>
            )
        }
    </Box>
);

ChatBlank.propTypes = {
    publicAIEnabled: PropTypes.bool,
    onTabChange: PropTypes.func,
    onAiChat: PropTypes.func,
    onMatchmaker: PropTypes.func,
};

