import { initializeAnalytics, isSupported, setAnalyticsCollectionEnabled, setConsent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider, getToken } from "firebase/app-check";
import { connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { firebaseConfig } from 'src/config';

let analytics;
isSupported().then((isSupported) => {
    if (isSupported) {
        analytics = initializeAnalytics(firebaseApp, {
            // options
            config: {
                'allow_ad_personalization_signals': false,
                'allow_google_signals': false,
                'cookie_prefix': '_ct',
            }
        });

        setAnalyticsCollectionEnabled(analytics, true);

        setConsent({
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            personalization_storage: 'denied',
        });
    } else {
        console.warn("Firebase Analytics is not supported in this environment.");
    }
});

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

// window.FIREBASE_APPCHECK_DEBUG_TOKEN = window.location.hostname === "localhost";
// const appCheck = initializeAppCheck(firebaseApp, {
//     provider: new ReCaptchaV3Provider('6LcJtcwpAAAAAKA_jrFecV37BU2eZvwfbg2k68Zt'),
//     isTokenAutoRefreshEnabled: true,
// });

// getToken(appCheck)
//     .then(() => {
//         console.log('App check token fetched');
//     })
//     .catch((error) => {
//         console.log('Error fetching app check token');
//         console.log(error.message)
//     })

export { firebaseApp, analytics, db };

// if (location.hostname === 'localhost') {
//     connectAuthEmulator(auth, 'http://localhost:9099');

//     if (process.env.REACT_APP_USE_FIRESTORE_EMULATOR === 'true') {
//         const firestore = getFirestore(firebaseApp);
//         connectFirestoreEmulator(firestore, 'localhost', 8080);
//     }
// }