import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {
    Box,
    Button,
    Drawer,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import { chatApi } from 'src/api/chat';
import { Scrollbar } from 'src/components/scrollbar';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { useSelector } from 'src/store';
import { ChatSidebarSearch } from './chat-sidebar-search';
import { ChatThreadItem } from './chat-thread-item';
import { ErrorBoundary } from 'src/components/error';
import * as Sentry from "@sentry/react";
import { logAnalyticsEvent } from 'src/utils/logging';

const getThreadKey = (thread, userId) => {
    let threadKey;

    if (thread.type === 'GROUP') {
        threadKey = thread.id;
    } else {
        // We hardcode the current user ID because the mocked that is not in sync
        // with the auth provider.
        // When implementing this app with a real database, replace this
        // ID with the ID from Auth Context.
        threadKey = thread.participantIds.find((participantId) => (participantId !== userId));
    }

    return threadKey;
};

const useThreads = () => {
    return useSelector((state) => state.chat.threads);
};

const useCurrentThreadId = () => {
    return useSelector((state) => state.chat.currentThreadId);
};

export const ChatSidebar = (props) => {
    const {
        ambassadors,
        activeAmbassadorID,
        threads,
        container,
        onClose,
        open,
        onSelectThread,
        onToggleSideBar,
        ...other
    } = props;
    const user = useMockedUser();
    const router = useRouter();
    const currentThreadId = useCurrentThreadId();
    const [searchFocused, setSearchFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const matchDownSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [errorItems, setErrorItems] = useState([]);

    const handleCompose = useCallback(() => {
        router.push(paths.dashboard.chat + '?compose=true');
    }, [router]);

    const handleSearchChange = useCallback(async (event) => {
        const { value } = event.target;

        setSearchQuery(value);

        if (!value) {
            setSearchResults([]);
            return;
        }

        try {
            const contacts = await chatApi.getContacts({ query: value });

            setSearchResults(contacts);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const handleSearchClickAway = useCallback(() => {
        if (searchFocused) {
            setSearchFocused(false);
            setSearchQuery('');
        }
    }, [searchFocused]);

    const handleSearchFocus = useCallback(() => {
        setSearchFocused(true);
    }, []);

    const handleSearchSelect = useCallback((contact) => {
        // We use the contact ID as a thread key
        const threadKey = contact.id;

        setSearchFocused(false);
        setSearchQuery('');

        router.push(paths.dashboard.chat + `?threadKey=${threadKey}`);
    }, [router]);

    const handleThreadSelect = useCallback((threadId) => {
        const thread = threads.byId[threadId];
        const threadKey = getThreadKey(thread, user.id);

        if (!threadKey) {
            router.push(paths.dashboard.chat);
        } else {
            router.push(paths.dashboard.chat + `?threadKey=${threadKey}`);
        }
    }, [router, threads, user]);

    const handleError = useCallback((error, info, itemID) => {
        console.log("ERROR HANDLED BY SIDEBAR", error, info, itemID);
        console.error(error, info, itemID);

        Sentry.captureException(error);

        setErrorItems((prevErrorItems) => {
            return [...prevErrorItems, itemID];
        });
    }, []);

    const content = (
        <div>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{ p: 2, py: matchDownSm ? 0 : 2 }}
            >
                <Typography
                    variant="h5"
                    sx={{ flexGrow: 1, textAlign: "start" }}
                >
                    Chats
                </Typography>
                {/* <Button
                    onClick={handleCompose}
                    startIcon={(
                        <SvgIcon>
                            <PlusIcon />
                        </SvgIcon>
                    )}
                    variant="contained"
                >
                    New Thread
                </Button> */}
                {mdUp && (
                    <Tooltip title="Hide Sidebar">
                        <IconButton onClick={onToggleSideBar} disabled={!activeAmbassadorID}>
                            <SvgIcon>
                                <KeyboardDoubleArrowLeftIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                )}
                {!mdUp && (
                    <IconButton onClick={onClose}>
                        <SvgIcon>
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                )}
            </Stack>
            <Box sx={{ display: searchFocused ? 'none' : 'block', textAlign: "start" }}>
                <Scrollbar>
                    <Stack
                        component="ul"
                        spacing={0.5}
                        sx={{
                            listStyle: 'none',
                            m: 0,
                            p: 2,
                            py: matchDownSm ? 0 : 2,
                        }}
                    >
                        {threads.allIds.sort((a, b) => threads.byId[a].id !== "dummyThread" && threads.byId[b].id !== "dummyThread" && threads.byId[a].id !== "aiChat" && threads.byId[b].id !== "aiChat" && threads.byId[a].id !== "matchmaker" && threads.byId[b].id !== "matchmaker" && threads.byId[a].lastMessageTime.toDate() - threads.byId[b].lastMessageTime.toDate()).map((threadId) => (
                            <ErrorBoundary key={threadId} itemID={threadId} onError={handleError}>
                                {
                                    (threads.byId[threadId].id === "dummyThread" || errorItems.includes(threadId)) ? (
                                        <Box sx={{ display: "none" }} />
                                    ) : <ChatThreadItem
                                        active={currentThreadId === threadId}
                                        onSelect={() => {
                                            onSelectThread(threadId);
                                            onClose();
                                        }}
                                        thread={threads.byId[threadId]}
                                        ambassadors={ambassadors}
                                    />
                                }
                            </ErrorBoundary>
                        ))}
                    </Stack>
                </Scrollbar>
            </Box>
        </div>
    );

    if (mdUp) {
        return (
            <Drawer
                anchor="left"
                open={open}
                PaperProps={{
                    sx: {
                        position: 'relative',
                        width: 380,
                        backgroundColor: open ? "initial" : "transparent",
                        opacity: open ? 1 : 0,
                    }
                }}
                SlideProps={{ container }}
                variant="persistent"
                {...other}>
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            hideBackdrop
            ModalProps={{
                container,
                sx: {
                    pointerEvents: 'none',
                    position: 'absolute'
                }
            }}
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: '100%',
                    width: matchDownSm ? "100%" : 380,
                    pointerEvents: 'auto',
                    position: 'absolute',
                    height: matchDownSm ? "calc(100vh - 100px)" : "70vh",
                }
            }}
            SlideProps={{ container }}
            variant="temporary"
            {...other}>
            {content}
        </Drawer>
    );
};

ChatSidebar.propTypes = {
    activeAmbassadorID: PropTypes.string,
    ambassadors: PropTypes.object,
    container: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    threads: PropTypes.object,
    onSelectThread: PropTypes.func,
    onToggleSideBar: PropTypes.func,
};
