import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { Avatar, Box, Button, Card, CardMedia, ClickAwayListener, Chip, Link, Stack, SvgIcon, Tooltip, Typography, useMediaQuery, LinearProgress } from '@mui/material';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useTheme } from "@mui/material/styles";
import { ReactionBarSelector } from '@charkour/react-reactions';
import { collection, onSnapshot, serverTimestamp, updateDoc, doc } from "firebase/firestore";
import { useEffect, useRef, useState } from 'react';
import { useAuth } from 'src/hooks/use-auth';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AssistantIcon from '@mui/icons-material/Assistant';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { analytics, db } from 'src/libs/firebase';
import { logAnalyticsEvent } from 'src/utils/logging';
import FailSafeAvatar from 'src/pages/components/fail-safe-avatar';
import { keyframes } from '@emotion/react';
import { Notifications } from '@mui/icons-material';
import { textWithClickableLinks } from 'src/utils/links';
import useSchool from 'src/hooks/use-school';
import AmbassadorProfile from 'src/pages/components/ambassador-profile';
import { isAmbassadorAvailable } from 'src/utils/ambassadors';

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 5vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;


export const ChatMessage = (props) => {
    const { id, queryID, isAiChat, ambassadors, isMatchmaker, onAiChat, onMatchmaker, onChatWith, onTabChange, ambassadorName, authorName, body, contentType, createdAt, isEdited, adminEdited, isUser, avatar, cartoonOptions, position, onEdit, onReply, replyMessageSender, replyMessageContent, activeThreadID, reactions, helpful, matchedAmbassador, onTurnNotificationsOn, ...other } = props;

    const { user, schoolSpecificUserInfo } = useAuth();
    const { school } = useSchool();
    const theme = useTheme();
    const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));

    const now = Date.now();
    const ago = (createdAt && (createdAt < now - 1000 * 60)) ? `${formatDistanceToNowStrict(createdAt)} ago` : "Just now";

    const ellipsisThreshold = matchUpSm ? 120 : 60;

    const [reactionSelectionOpen, setReactionSelectionOpen] = useState(false);

    const anonUserID = localStorage.getItem('visitorId');
    const messageQuery = (isAiChat || isMatchmaker) && position === 'left' && queryID ? doc(db, `${!user ? "anon" : "users"}/${!user ? anonUserID : user.id}/${isMatchmaker ? 'matchmaker' : 'aiChat'}`, queryID) : doc(db, `threads/${activeThreadID}/messages`, id);

    const showAvatar = !isUser && (!!avatar || !!cartoonOptions) && position === 'left';

    const likeRef = useRef(null);
    const loveRef = useRef(null);
    const laughRef = useRef(null);
    const surpriseRef = useRef(null);
    const thinkingRef = useRef(null);
    const pensiveRef = useRef(null);

    useEffect(() => {
        if (!!user && !!reactions && reactions.hasOwnProperty(user?.id)) {
            const reactionRefs = {
                "👍": likeRef,
                "❤️": loveRef,
                "😆": laughRef,
                "😮": surpriseRef,
                "🤔": thinkingRef,
                "😔": pensiveRef
            };

            for (const [reaction, ref] of Object.entries(reactionRefs)) {
                let parentEl = ref.current?.parentElement?.parentElement;
                if (parentEl) {
                    if (!!user && reactions[user.id] === reaction) {
                        parentEl.style.backgroundColor = "lightcyan";
                        parentEl.style.borderRadius = "33%";
                    } else {
                        parentEl.style.backgroundColor = "transparent";
                    }
                }
            }
        }
    }, [reactions, likeRef.current, loveRef.current, laughRef.current, surpriseRef.current, thinkingRef.current, pensiveRef.current]);


    const ThumbsUpIcon = helpful === "yes" ? ThumbUpIcon : ThumbUpOffAltIcon;
    const ThumbsDownIcon = helpful === "no" ? ThumbDownIcon : ThumbDownOffAltIcon;

    console.log("matchedAmbassador", matchedAmbassador);
    console.log("matchedAmbassador ambassadors", ambassadors);

    const ambassador = matchedAmbassador && ambassadors ? ambassadors?.byId[`ambassador_${matchedAmbassador}`] : null;

    return (
        <Box
            id={id}
            sx={{
                display: 'flex',
                alignItems: position === 'right' ? 'flex-end' : 'flex-start',
                animation: `${fadeUp} 0.5s ease-in-out`,
                mt: '8px !important',
            }}
            {...other}
            mt="8px !important"
        >
            <Stack
                alignItems="flex-start"
                direction={position === 'right' ? 'row-reverse' : 'row'}
                spacing={0}
                sx={{
                    maxWidth: 500,
                    ml: position === 'right' ? 'auto' : 0,
                    mr: position === 'left' ? 'auto' : 0,
                    columnGap: "7px",
                }}
            >
                <Box sx={{ flexGrow: 1, alignSelf: "flex-end" }}>
                    <Card
                        sx={{
                            backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
                            color: position === 'right' ? 'primary.contrastText' : 'text.primary',
                            px: 2,
                            py: 1,
                            textAlign: 'left',
                        }}
                    >
                        {!!replyMessageContent && !!replyMessageSender && (
                            <Stack
                                borderLeft={"3px solid gray"}
                                paddingLeft={1}
                                mb={2}
                            >
                                <Typography variant='body2' color="lightgray">
                                    {replyMessageSender}
                                </Typography>
                                <Typography variant='body2' color="lightgray">
                                    {replyMessageContent.length > ellipsisThreshold ? `${replyMessageContent.substring(0, ellipsisThreshold)}...` : replyMessageContent}
                                </Typography>
                            </Stack>
                        )}
                        <Box sx={{ mb: 1, display: "flex", flexDirection: "row" }}>
                            {
                                showAvatar && (
                                    <FailSafeAvatar
                                        profilePicURL={avatar}
                                        sz={25}
                                        sx={{ marginRight: position === 'left' ? 0 : 1 }}
                                        cartoonOptions={cartoonOptions}
                                    />
                                )
                            }
                            <Typography
                                color="inherit"
                                sx={{ textAlign: "start !important", display: "block", marginLeft: position === 'left' ? 1 : null }}
                                variant="subtitle2"
                            >
                                {authorName === "AI Bot" ? "⚡ " : ""}{authorName}
                            </Typography>
                        </Box>
                        {contentType === 'image' && (
                            <CardMedia
                                onClick={() => { }}
                                image={body}
                                sx={{
                                    height: 200,
                                    width: 200
                                }}
                            />
                        )}
                        {contentType === 'text' && (
                            position === 'right' ? (
                                <Typography
                                    color="inherit"
                                    variant="body1"
                                    sx={{
                                        animation: `${fadeIn} 1.2s ease-in-out`,
                                        whiteSpace: 'pre-wrap',
                                    }}
                                >
                                    {body}
                                </Typography>
                            ) : (
                                <div
                                    style={{
                                        animation: `${fadeIn} 1.2s ease-in-out`,
                                        whiteSpace: 'pre-wrap',
                                        lineHeight: '1.5',
                                        fontWeight: 400,
                                        fontSize: '1rem',
                                        margin: 0,
                                        color: 'inherit',
                                        fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: textWithClickableLinks(body),
                                    }}
                                />
                            )
                        )}
                        {
                            ((isAiChat && authorName === 'AI Bot') || isMatchmaker) && body === "Thinking..." && (
                                <Box sx={{ width: '100%', py: 1 }}>
                                    <LinearProgress color='primary' />
                                </Box>
                            )
                        }
                    </Card>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ((isAiChat || isMatchmaker) && position === 'left') ? 'space-between' : position === 'right' ? 'flex-end' : 'flex-start',
                            mt: ((isAiChat || isMatchmaker) && position === 'left') ? 1 : 0,
                            px: 2,
                            columnGap: "7px",
                        }}
                    >
                        <Typography
                            color="text.secondary"
                            noWrap
                            variant="caption"
                            display={createdAt ? 'block' : 'none'}
                        >
                            {ago}
                        </Typography>
                        {
                            ((isAiChat || isMatchmaker) && position === 'left' && id !== '5e867f0a5bc0ff2bfa07bfa6' && !!queryID) ? (
                                <Stack direction={"row"} columnGap={0.5}>
                                    <Typography variant="caption" color="text.secondary">
                                        Helpful?
                                    </Typography>
                                    <ThumbsUpIcon color='primary' onClick={() => {
                                        updateDoc(messageQuery, {
                                            helpful: "yes",
                                        });
                                        logAnalyticsEvent("message_helpful", {
                                            messageID: id,
                                            helpful: "yes",
                                        });
                                    }} fontSize={"18px"} sx={{ cursor: "pointer" }} />
                                    <ThumbsDownIcon color='primary' onClick={() => {
                                        updateDoc(messageQuery, {
                                            helpful: "no",
                                        });
                                        logAnalyticsEvent("message_helpful", {
                                            messageID: id,
                                            helpful: "no",
                                        });
                                    }} fontSize={"18px"} sx={{ cursor: "pointer" }} />
                                </Stack>
                            ) : (
                                <Typography variant="caption" color="text.secondary">
                                    {adminEdited ? '(Edited by School Admin)' : isEdited ? '(Edited)' : ''}
                                </Typography>
                            )
                        }
                        {
                            isMatchmaker && position === 'left' && id === '5e867f0a5bc0ff2bfa07bfa6' && !user && (
                                <Button variant='outlined' color='primary' onClick={() => onTabChange({ whichTab: 'settings' })} sx={{ justifyContent: 'left' }}>
                                    Take me to sign in
                                </Button>
                            )
                        }
                    </Box>
                    {
                        isMatchmaker && position === 'left' && matchedAmbassador && !!user && (
                            <AmbassadorProfile isAvailable={isAmbassadorAvailable(ambassador)} schoolColor={school?.primaryColor} widgetConfig={school?.widgetConfig} schoolType={school?.type} ambassador={ambassador} onChatWith={onChatWith} fromMatchMaker={true} />
                        )
                    }
                    {
                        id.includes("-not-available") && (
                            <Stack spacing={1} width={matchUpSm ? "80%" : "100%"} marginRight={"auto"} sx={{ mt: 1 }}>
                                {
                                    user?.notificationPreference !== "SMS" && user?.notificationPreference !== "email" && user?.notificationPreference !== "both" && (
                                        <Button startIcon={(
                                            <SvgIcon>
                                                <Notifications />
                                            </SvgIcon>
                                        )} variant="outlined" color="primary" onClick={() => {
                                            onTurnNotificationsOn();
                                        }}>
                                            Turn On Notifications
                                        </Button>
                                    )
                                }
                                <Button startIcon={(
                                    <SvgIcon>
                                        <ConnectWithoutContactIcon />
                                    </SvgIcon>
                                )} variant="outlined" color="primary" onClick={() => {
                                    onTabChange({
                                        whichTab: 'students',
                                    });
                                }}>
                                    Connect with Another Ambassador
                                </Button>
                                {
                                    school?.enablePublicAI && (
                                        <Button startIcon={(
                                            <SvgIcon>
                                                <AssistantIcon />
                                            </SvgIcon>
                                        )} variant="outlined" color="primary" onClick={() => {
                                            onAiChat();
                                        }}>
                                            Get Instant Answers from our AI Bot
                                        </Button>
                                    )
                                }

                            </Stack>
                        )
                    }
                    <Stack mr={position === "right" ? "5px" : "0px"} ml={position === "right" ? "0px" : "5px"} columnGap="5px" direction="row" justifyContent={position === "right" ? "flex-end" : "flex-start"}>
                        {!!user && reactions && Object.entries(reactions).map(([userID, reaction]) => (
                            <Tooltip key={userID} title={userID === user.id ? "Me" : ambassadorName}>
                                <Chip
                                    sx={{
                                        display: !!reaction ? "inline-flex" : "none",
                                        p: 0,
                                        height: "26px",
                                        background: "transparent",
                                        borderStyle: "solid",
                                        borderWidth: userID === user.id ? "2px" : "1px",
                                        borderColor: userID === user.id ? "lightcyan" : "lightgray",
                                        '& .MuiChip-label': {
                                            fontSize: "1.2em",
                                            lineHeight: "1em",
                                        },
                                    }}
                                    label={reaction}
                                />
                            </Tooltip>
                        ))}
                    </Stack>
                </Box>
                {
                    !isAiChat && !!user && !isMatchmaker && (
                        <Stack
                            // pt={0.5}
                            // rowGap={0.5}
                            sx={{
                                alignItems: position === "right" ? "flex-end" : "flex-start",
                                opacity: (!!id && id !== '5e867f0a5bc0ff2bfa07bfa6') ? 1 : 0
                            }}
                        >
                            <Button
                                sx={{
                                    p: 0.25,
                                    color: "lightgray",
                                    '&:hover': {
                                        color: (theme) => theme.palette.primary.main
                                    },
                                    minWidth: "auto",
                                    justifyContent: position === "right" ? "flex-end" : "flex-start",
                                }}
                                disabled={!id || id === '5e867f0a5bc0ff2bfa07bfa6'}
                                onClick={() => onReply(id)}
                            >
                                <ReplyOutlinedIcon sx={{ transform: `scaleX(${position === 'right' ? 1 : -1})` }} />
                            </Button>
                            <Stack direction={position === "right" ? "row-reverse" : "row"}>
                                <Button
                                    sx={{
                                        p: 0.25,
                                        color: "lightgray",
                                        '&:hover': {
                                            color: (theme) => theme.palette.primary.main
                                        },
                                        minWidth: "auto",
                                        justifyContent: position === "right" ? "flex-end" : "flex-start",
                                    }}
                                    disabled={!id || id === '5e867f0a5bc0ff2bfa07bfa6'}
                                    onClick={(e) => {
                                        if (position === "right") {
                                            onEdit(id);
                                        } else {
                                            setReactionSelectionOpen(true);
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }
                                    }}
                                >
                                    {position === "right" ? <ModeEditIcon /> : <AddReactionOutlinedIcon />}
                                </Button>
                            </Stack>
                            <ClickAwayListener onClickAway={(e) => {
                                if (reactionSelectionOpen) {
                                    setReactionSelectionOpen(false)
                                }
                            }}>
                                <ReactionBarSelector
                                    reactions={[
                                        { label: "Like", node: <div ref={likeRef}>👍</div>, key: "satisfaction" },
                                        { label: "Love", node: <div ref={loveRef}>❤️</div>, key: "love" },
                                        { label: "Haha", node: <div ref={laughRef}>😆</div>, key: "laughing" },
                                        { label: "Wow", node: <div ref={surpriseRef}>😮</div>, key: "surprise" },
                                        { label: "Hmm", node: <div ref={thinkingRef}>🤔</div>, key: "thinking" },
                                        { label: "Sad", node: <div ref={pensiveRef}>😔</div>, key: "pensive" },
                                    ]}
                                    style={{
                                        display: reactionSelectionOpen ? "flex" : "none",
                                        position: "absolute",
                                        columnGap: "0em",
                                        paddingLeft: "1em",
                                        paddingRight: "1em",
                                        // marginRight: position === "right" ? "0.5em" : 0,
                                        // marginLeft: position === "left" ? "0.5em" : 0,
                                        marginTop: "1.5em",
                                        zIndex: 100,
                                        flexDirection: matchUpSm ? "row" : "column",
                                    }}
                                    iconSize="20px"
                                    onSelect={async (reaction) => {
                                        const reactionToEmoji = {
                                            "satisfaction": "👍",
                                            "love": "❤️",
                                            "laughing": "😆",
                                            "surprise": "😮",
                                            "thinking": "🤔",
                                            "pensive": "😔",
                                        };

                                        let newReactions = reactions ? reactions : {};
                                        if (newReactions[user.id] === reactionToEmoji[reaction]) {
                                            newReactions[user.id] = null;
                                        } else {
                                            newReactions[user.id] = reactionToEmoji[reaction];
                                        }

                                        logAnalyticsEvent("reacting_to_message", {
                                            messageID: id,
                                            userID: user.id,
                                            reaction: reaction,
                                        });

                                        // Edit Firestore message document
                                        try {
                                            await updateDoc(messageQuery, {
                                                reactions: newReactions,
                                            });
                                        } catch (err) {
                                            logAnalyticsEvent("error_reacting_to_message", {
                                                error: err,
                                                messageID: id,
                                                userID: user.id,
                                                reaction: reaction,
                                            });
                                        }
                                        setReactionSelectionOpen(false);
                                    }}
                                />

                            </ClickAwayListener>
                        </Stack>
                    )
                }
            </Stack>
        </Box >
    );
};

ChatMessage.propTypes = {
    id: PropTypes.string,
    queryID: PropTypes.string,
    isAiChat: PropTypes.bool,
    isMatchmaker: PropTypes.bool,
    ambassadors: PropTypes.object,
    onTabChange: PropTypes.func,
    onChatWith: PropTypes.func,
    authorName: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    createdAt: PropTypes.number,
    position: PropTypes.oneOf(['left', 'right']),
    onReply: PropTypes.func,
    onEdit: PropTypes.func,
    replyMessageSender: PropTypes.string,
    replyMessageContent: PropTypes.string,
    activeThreadID: PropTypes.string,
    reactions: PropTypes.object,
    helpful: PropTypes.string,
    matchedAmbassador: PropTypes.string,
    ambassadorName: PropTypes.string,
    isEdited: PropTypes.bool,
    adminEdited: PropTypes.bool,
    isUser: PropTypes.bool,
    avatar: PropTypes.string,
    onTurnNotificationsOn: PropTypes.func,
    onAiChat: PropTypes.func,
    onMatchmaker: PropTypes.func,
};
