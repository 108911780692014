import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { chatApi } from 'src/api/chat';
import { Scrollbar } from 'src/components/scrollbar';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { useDispatch, useSelector } from 'src/store';
import { thunks } from 'src/thunks/chat';
import { ChatMessageAdd } from './chat-message-add';
import { ChatMessages } from './chat-messages';
import { ChatThreadToolbar } from './chat-thread-toolbar';
import { useAuth } from 'src/hooks/use-auth';
import { useMounted } from 'src/hooks/use-mounted';
import { collection, onSnapshot, serverTimestamp, addDoc, updateDoc, setDoc, doc, arrayUnion } from "firebase/firestore";
import { ChatMessageReply } from './chat-message-reply';
import { ChatMessageEdit } from './chat-message-edit';
import ChatPreMessageForm from './chat-pre-message-form';
import ChatPostMessageForm from './chat-post-message-form';
import { isAmbassadorAvailable } from 'src/utils/ambassadors';
import { firebaseApp, analytics, db } from 'src/libs/firebase';
import { logAnalyticsEvent } from 'src/utils/logging';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Get the Functions instance
const functions = getFunctions(firebaseApp);

const useMessagesScroll = (messagesCount, messagesProcessed) => {
    const messagesRef = useRef(null);

    const handleUpdate = useCallback(() => {
        console.log("Messages Count: ", messagesCount);
        console.log("Messages Processed: ", messagesProcessed);
        // Thread has no messages
        if (!messagesCount) {
            return;
        }

        // Ref is not used
        if (!messagesRef.current) {
            return;
        }

        const container = messagesRef.current;
        const scrollElement = container.getScrollElement();

        if (scrollElement) {
            setTimeout(() => {
                // Check if scrollElement is still available
                if (scrollElement) {
                    scrollElement.scrollTop = container.el.scrollHeight;
                }
            }, 150);
        }
    }, [messagesCount, messagesProcessed]);

    useEffect(() => {
        handleUpdate();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [messagesCount, messagesProcessed]);

    return {
        messagesRef
    };
};

const useMessages = (activeThreadID) => {
    const isMounted = useMounted();
    const [messages, setMessages] = useState({
        byId: {},
        allIds: []
    });

    const handleMessagesGet = useCallback(async () => {
        const messagesQuery = collection(db, `threads/${activeThreadID}/messages`);

        try {
            return onSnapshot(messagesQuery, async (snapshot) => {
                const docChanges = snapshot.docChanges();
                for (const change of docChanges) {
                    if (isMounted()) {
                        if (change.type === "added" || change.type === "modified") {
                            const messageData = change.doc.data();
                            if (messageData.time && !messageData.isDeleted && !messageData.needsModeration && !messageData.adminDeleted) {
                                setMessages((prevState) => {
                                    const newAllIds = Array.from(new Set([...prevState.allIds, change.doc.id]));
                                    return {
                                        byId: {
                                            ...prevState.byId,
                                            [change.doc.id]: messageData
                                        },
                                        allIds: newAllIds
                                    };
                                });
                            }
                        }
                        if (change.type === "removed") {
                            setMessages((prevState) => {
                                const { [change.doc.id]: _, ...rest } = prevState.byId;
                                return {
                                    byId: rest,
                                    allIds: prevState.allIds.filter((id) => id !== change.doc.id)
                                };
                            });
                        }
                    }
                }

                if (activeThreadID !== "dummyThread") {
                    try {
                        // After retrieving the messages, update the prospectUnreadCount of the thread to be 0
                        const threadRef = doc(db, 'threads', activeThreadID);
                        await updateDoc(threadRef, {
                            prospectUnreadCount: 0
                        });
                    } catch (err) {
                        console.log("Failed to update prospect count for thread", activeThreadID);
                        console.error(err);

                        logAnalyticsEvent('error_prospect_count_thread', {
                            description: err.message,
                            thread: activeThreadID,
                        });
                    }
                }
            }, (err) => {
                console.error("Error fetching messages for thread", activeThreadID);
                console.error(err);

                logAnalyticsEvent('error_snapshot_messages', {
                    description: err.message,
                    thread: activeThreadID,
                });
            });
        } catch (err) {
            console.error(err);

            logAnalyticsEvent('error_fetching_messages', {
                description: err.message,
                thread: activeThreadID,
            });
        }

        return null;
    }, [isMounted, activeThreadID]);

    useEffect(() => {
        setMessages({
            byId: {},
            allIds: []
        });

        const unsubscribe = !!activeThreadID && activeThreadID !== "dummyThread" && activeThreadID !== 'aiChat' && activeThreadID !== 'matchmaker' ? handleMessagesGet() : null;

        return () => {
            // Check if unsubscribe is a function before calling it
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeThreadID]);

    return messages;
};

const useAIMessages = (userID, isAnon = false) => {
    const isMounted = useMounted();
    const [messages, setMessages] = useState({
        byId: {},
        allIds: []
    });

    const handleMessagesGet = useCallback(async () => {
        const messagesQuery = collection(db, `${isAnon ? 'anon' : 'users'}/${userID}/aiChat`);

        try {
            return onSnapshot(messagesQuery, async (snapshot) => {
                const docChanges = snapshot.docChanges();
                for (const change of docChanges) {
                    if (isMounted()) {
                        if (change.type === "added" || change.type === "modified") {
                            const messageData = change.doc.data();
                            if (messageData.time) {
                                const { query, response, hasProcessed, hasError } = messageData;

                                setMessages((prevState) => {
                                    const newAllIds = Array.from(new Set([...prevState.allIds, change.doc.id, `${change.doc.id}-response`]));
                                    let newById = [{
                                        id: change.doc.id,
                                        attachments: [],
                                        body: query,
                                        contentType: 'text',
                                        authorId: userID,
                                        createdAt: messageData.time?.toDate(),
                                    }];

                                    if (response && hasProcessed && !hasError) {
                                        newById.push({
                                            id: `${change.doc.id}-response`,
                                            attachments: [],
                                            body: response,
                                            contentType: 'text',
                                            authorId: 'aiChat',
                                            createdAt: messageData.time?.toDate(),
                                            helpful: messageData.helpful,
                                            queryID: change.doc.id,
                                        });
                                    } else if (hasError) {
                                        newById.push({
                                            id: `${change.doc.id}-response`,
                                            attachments: [],
                                            body: "I'm sorry, I couldn't process your message. Please try again.",
                                            contentType: 'text',
                                            authorId: 'aiChat',
                                            createdAt: messageData.time?.toDate(),
                                        });
                                    } else {
                                        newById.push({
                                            id: `${change.doc.id}-response`,
                                            attachments: [],
                                            body: "Thinking...",
                                            contentType: 'text',
                                            authorId: 'aiChat',
                                            createdAt: messageData.time?.toDate(),
                                        });
                                    }

                                    return {
                                        byId: {
                                            ...prevState.byId,
                                            ...newById.reduce((acc, message) => {
                                                acc[message.id] = message;
                                                return acc;
                                            }, {}),
                                        },
                                        allIds: newAllIds,
                                    };
                                });
                            }
                        }
                        if (change.type === "removed") {
                            setMessages((prevState) => {
                                const { [change.doc.id]: _, ...rest } = prevState.byId;
                                return {
                                    byId: rest,
                                    allIds: prevState.allIds.filter((id) => id !== change.doc.id)
                                };
                            });
                        }
                    }
                }
            }, (err) => {
                console.error("Error fetching messages for user", userID);
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }

        return null;
    }, [isMounted, userID]);

    useEffect(() => {
        setMessages({
            byId: {},
            allIds: []
        });

        const unsubscribe = !!userID ? handleMessagesGet() : null;

        return () => {
            // Check if unsubscribe is a function before calling it
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userID]);

    return messages;
};

const useMatchmakerMessages = (userID) => {
    const isMounted = useMounted();
    const [messages, setMessages] = useState({
        byId: {},
        allIds: []
    });

    const handleMessagesGet = useCallback(async () => {
        const messagesQuery = collection(db, `users/${userID}/matchmaker`);

        try {
            return onSnapshot(messagesQuery, async (snapshot) => {
                const docChanges = snapshot.docChanges();
                for (const change of docChanges) {
                    if (isMounted()) {
                        if (change.type === "added" || change.type === "modified") {
                            const messageData = change.doc.data();
                            if (messageData.time) {
                                const { query, response, hasProcessed, hasError } = messageData;

                                setMessages((prevState) => {
                                    const newAllIds = Array.from(new Set([...prevState.allIds, change.doc.id, `${change.doc.id}-response`]));
                                    let newById = [{
                                        id: change.doc.id,
                                        attachments: [],
                                        body: query,
                                        contentType: 'text',
                                        authorId: userID,
                                        createdAt: messageData.time?.toDate(),
                                    }];

                                    if (response && hasProcessed && !hasError) {
                                        newById.push({
                                            id: `${change.doc.id}-response`,
                                            attachments: [],
                                            body: response,
                                            contentType: 'text',
                                            authorId: 'matchmaker',
                                            createdAt: messageData.time?.toDate(),
                                            helpful: messageData.helpful,
                                            queryID: change.doc.id,
                                            // Figure out if it starts with ambassador_ and remove that prefix if it does
                                            matchedAmbassador: messageData.ambassadorID?.startsWith("ambassador_") ? messageData.ambassadorID.substring(11) : messageData.ambassadorID,
                                        });
                                    } else if (hasError) {
                                        newById.push({
                                            id: `${change.doc.id}-response`,
                                            attachments: [],
                                            body: response || "I'm sorry, I couldn't process that. Please try again.",
                                            contentType: 'text',
                                            authorId: 'matchmaker',
                                            createdAt: messageData.time?.toDate(),
                                        });
                                    } else {
                                        newById.push({
                                            id: `${change.doc.id}-response`,
                                            attachments: [],
                                            body: "Thinking...",
                                            contentType: 'text',
                                            authorId: 'matchmaker',
                                            createdAt: messageData.time?.toDate(),
                                        });
                                    }

                                    return {
                                        byId: {
                                            ...prevState.byId,
                                            ...newById.reduce((acc, message) => {
                                                acc[message.id] = message;
                                                return acc;
                                            }, {}),
                                        },
                                        allIds: newAllIds,
                                    };
                                });
                            }
                        }
                        if (change.type === "removed") {
                            setMessages((prevState) => {
                                const { [change.doc.id]: _, ...rest } = prevState.byId;
                                return {
                                    byId: rest,
                                    allIds: prevState.allIds.filter((id) => id !== change.doc.id)
                                };
                            });
                        }
                    }
                }
            }, (err) => {
                console.error("Error fetching messages for user", userID);
                console.error(err);
            });
        } catch (err) {
            console.error(err);
        }

        return null;
    }, [isMounted, userID]);

    useEffect(() => {
        setMessages({
            byId: {},
            allIds: []
        });

        const unsubscribe = !!userID ? handleMessagesGet() : null;

        return () => {
            // Check if unsubscribe is a function before calling it
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userID]);

    return messages;
};

export const ChatThread = (props) => {
    const { activeAmbassadorID, ambassadors, activeThreadID, activeThread, activeSchool, prospectMuted, onChatWith, onTabChange, showToggle, onToggleSideBar, onTurnNotificationsOn, onAiChat, onMatchmaker, ...other } = props;
    const dispatch = useDispatch();
    const isAiChat = activeThreadID === "aiChat" || activeAmbassadorID === "aiChat";
    const isMatchmaker = activeThreadID === "matchmaker" || activeAmbassadorID === "matchmaker";

    const { user, schoolSpecificUserInfo, aiChatLoaded, aiChatHistory } = useAuth();

    const anonUserID = localStorage.getItem('visitorId');
    const aiMessages = useAIMessages(user?.id ?? anonUserID, !user);
    const matchmakerMessages = useMatchmakerMessages(user?.id);
    const messages = useMessages(activeThreadID);
    // Count the number of ai messages that have been processed
    const aiMessagesProcessed = aiMessages?.allIds?.length > 0 ? Object.values(aiMessages.byId).filter((message) => message.hasProcessed).length : 0;
    const { messagesRef } = useMessagesScroll(isAiChat ? aiMessages.allIds.length : matchmakerMessages ? matchmakerMessages.allIds.length : messages.allIds.length, aiMessagesProcessed);

    const [notAvailableMessageSentTime, setNotAvailableMessageSentTime] = useState(null);
    // const messagesRef = useRef(null);
    const [body, setBody] = useState('');

    const [replyMessageID, setReplyMessageID] = useState("");
    const [editMessageID, setEditMessageID] = useState("");

    const [tooManyMessagesWarning, setTooManyMessagesWarning] = useState("");
    const [messageTooLongWarning, setMessageTooLongWarning] = useState("");

    const [showPreMessageQuestions, setShowPreMessageQuestions] = useState(false);
    const [preMessageQuestionsSkipped, setPreMessageQuestionsSkipped] = useState(false);

    const [showPostMessageQuestions, setShowPostMessageQuestions] = useState(false);
    const [postMessageQuestionsSkipped, setPostMessageQuestionsSkipped] = useState(false);

    const [sendLoading, setSendLoading] = useState(false);

    const ambassadorAvailable = isAmbassadorAvailable(ambassadors.byId[activeAmbassadorID]);

    const ranFetchedMessages = useRef(false);
    const unsubscribeMessagesRef = useRef(null);

    const handleSend = useCallback(async (body) => {
        if (!user && !isAiChat) {
            // Prompt the user to sign in
            onTabChange({ whichTab: "settings" });
            return;
        }
        // If user profile still not complete, prompt them first
        if ((!schoolSpecificUserInfo?.alreadyApplied || !schoolSpecificUserInfo?.plannedEnrollment || !schoolSpecificUserInfo?.selectedPrograms?.length || !schoolSpecificUserInfo?.selectedCountryOrProvince) && !preMessageQuestionsSkipped && !isAiChat) {
            setShowPreMessageQuestions(true);
            return;
        }

        setSendLoading(true);

        if (!!editMessageID) {
            // Update the message
            const messageQuery = doc(
                db,
                `threads/${activeThreadID}/messages`,
                editMessageID
            );

            try {
                await updateDoc(messageQuery, {
                    content: body,
                    isEdited: true,
                });
            } catch (error) {
                console.error("Error updating message:", error);
            }
        } else if (isAiChat) {
            if (body.length > 0) {
                if (!user && !aiMessages.allIds.length) {
                    // This must mean the anon user does not yet have a user doc - create one
                    await setDoc(doc(db, `anon/${anonUserID}`), {
                        name: "Anonymous User",
                        email: "",
                        photoURL: "",
                        isAnonymous: true,
                        agreedToMarketing: null,
                        created: new Date(),
                        schools: [activeSchool?.id],
                        type: "prospect",
                    });
                }

                const newQueryData = {
                    query: body,
                    hasProcessed: false,
                    response: "",
                    time: serverTimestamp(),
                    hasError: false,
                    companySearchConfigID: activeSchool?.companySearchConfigID,
                    companySlug: activeSchool?.id,
                    companyName: activeSchool?.name,
                    firstName: user?.name,
                    isProspect: true,
                    prospectID: user?.id ?? anonUserID,
                };

                try {
                    const aiChatCollection = !user ? `anon/${anonUserID}/aiChat` : `users/${user.id}/aiChat`;
                    await addDoc(collection(db, aiChatCollection), newQueryData);
                } catch (error) {
                    console.error("Error adding AI message:", error);
                }
            }
        } else if (isMatchmaker) {
            // Add the message to the matchmaker collection
            if (body.length > 0) {
                const newQueryData = {
                    query: body,
                    hasProcessed: false,
                    response: "",
                    time: serverTimestamp(),
                    hasError: false,
                    companySlug: activeSchool?.id,
                    firstName: user?.name ?? "",
                    programsOfInterest: schoolSpecificUserInfo?.selectedPrograms ?? [],
                    selectedCountryOrProvince: schoolSpecificUserInfo?.selectedCountryOrProvince ?? "",
                    prospectID: user?.id ?? anonUserID,
                };

                try {
                    await addDoc(collection(db, `users/${user.id}/matchmaker`), newQueryData);
                } catch (error) {
                    console.error("Error adding matchmaker message:", error);
                }
            }
        } else if (!tooManyMessagesWarning && !messageTooLongWarning && body.length > 0) {
            // Check if we need to create the thread first
            let effectiveThreadID = activeThreadID;
            if (!activeThreadID || activeThreadID === "dummyThread") {
                // Create the thread
                const newThread = {
                    prospect: doc(db, "users", user.id),
                    prospectName: user.name,
                    ambassadorName: ambassadors.byId[activeAmbassadorID].firstName,
                    ambassadorAvatar: ambassadors.byId[activeAmbassadorID].photoURL,
                    ambassador: doc(db, "users", activeAmbassadorID),
                    contributors: [doc(db, "users", activeAmbassadorID)],
                    school: doc(db, "schools", activeSchool?.id),
                    schoolName: activeSchool?.name,
                    schoolKnowledgeBaseID: activeSchool?.superpoweredID,
                    schoolWebsitesPresetID: activeSchool?.websitesPresetID,
                    lastMessageContent: ambassadors.byId[activeAmbassadorID]?.profileIntroText || "Hey! Got any questions for me?",
                    lastMessageTime: new Date(),
                    lastMessageSender: doc(db, "users", activeAmbassadorID),
                    prospectUnreadCount: 0,
                    totalProspectMessages: 0,
                    type: "direct",
                    report: null,
                    created: new Date(),
                };

                logAnalyticsEvent('creating_thread', {
                    school: activeSchool?.id,
                    ambassador: activeAmbassadorID,
                });

                // Get a reference to the Firestore collection
                const collectionRef = collection(db, 'threads');
                // Add a new document with an automatically generated ID
                try {
                    const docRef = await addDoc(collectionRef, newThread);
                    effectiveThreadID = docRef.id;
                } catch (e) {
                    console.error('Error writing document: ', e);
                    logAnalyticsEvent('error_creating_thread', {
                        error: e,
                        school: activeSchool?.id,
                        ambassador: activeAmbassadorID,
                    });
                }
            }

            const replyMessageRef = !!replyMessageID ? doc(db, `threads/${effectiveThreadID}/messages`, replyMessageID) : null;
            const message = {
                content: body,
                sender: doc(db, "users", user.id),
                time: serverTimestamp(),
                isNotProspect: false,
                replyMessage: replyMessageRef,
                school: doc(db, "schools", activeSchool?.id),
                schoolName: activeSchool?.name,
                existedBefore: false,
            };

            logAnalyticsEvent('sending_message', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
                content: body,
                replying: !!replyMessageID,
                editing: !!editMessageID,
            });

            const messagesQuery = collection(db, `threads/${effectiveThreadID}/messages`);
            try {
                // Save new message to Firestore
                await addDoc(messagesQuery, message);

                // If the ambassador is not available, send a message saying they are not available (with time 100ms in the future)
                if (!ambassadorAvailable) {
                    setNotAvailableMessageSentTime(new Date(Date.now() + 100));
                }
            } catch (err) {
                console.error(err);
                logAnalyticsEvent('error_sending_message', {
                    error: err,
                    school: activeSchool?.id,
                    ambassador: activeAmbassadorID,
                    content: body,
                });
            }
        }

        // Reset reply message ID
        setReplyMessageID("");
        // Reset edit message ID
        setEditMessageID("");
        // Reset the message body
        setBody("");

        setSendLoading(false);

        // Check if user has already set their notification preference
        if (!user?.notificationPreference && !postMessageQuestionsSkipped) {
            // Prompt the user to set their notification preference
            setShowPostMessageQuestions(true);
        }
    }, [ambassadors, activeThread, schoolSpecificUserInfo, replyMessageID, editMessageID, body, showPreMessageQuestions, preMessageQuestionsSkipped, tooManyMessagesWarning, messageTooLongWarning, activeThreadID, postMessageQuestionsSkipped, showPostMessageQuestions, user]);

    const handleCommunicationsPreferences = useCallback(async (communicationPreferences) => {
        // Update the user's notification preference
        const { notificationPreference, phone, agreedToMarketing } = communicationPreferences;

        // Update the user within the prospects collection of the schools collection in Firestore
        const userDoc = doc(db, `users/${user.id}`);
        const updateData = {
            notificationPreference,
            phone,
        };

        if (agreedToMarketing) {
            updateData.agreedToMarketing = arrayUnion(activeSchool.id);
        }

        setDoc(userDoc, updateData, { merge: true });
    }, [activeSchool, user]);

    const handleMute = useCallback(async () => {
        if (!!activeThread && !!activeThreadID && activeThreadID !== "dummyThread" && !!activeSchool && activeThreadID !== "aiChat" && activeThreadID !== "matchmaker") {
            logAnalyticsEvent('muting_ambassador', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
            });
            // Update the thread doc to mute the prospect
            const threadQuery = doc(db, 'threads', activeThreadID);
            try {
                await updateDoc(threadQuery, {
                    prospectMuted: activeThread.prospectMuted ? false : true
                });
            } catch (error) {
                console.error("Error muting prospect:", error);
                logAnalyticsEvent('error_muting_ambassador', {
                    error: error,
                    school: activeSchool?.id,
                    ambassador: activeAmbassadorID,
                });
            }
        }
    }, [activeSchool, activeThread, user]);

    const handleReplyCancel = () => {
        setReplyMessageID("");
    };

    const handleEditCancel = () => {
        setEditMessageID("");
    };

    const handleChange = useCallback((event) => {
        console.log("user, activeThreadID, isMatchmaker: ", user, activeThreadID, isMatchmaker);
        if (!user && !isAiChat) {
            // Prompt the user to sign in
            onTabChange({ whichTab: "settings" });
            return;
        }
        setBody(event.target.value);
    }, [isMatchmaker, isAiChat, user]);

    const replyMessageSender = messages.byId[replyMessageID]?.isNotProspect ? activeThread.prospectName : "Me";
    const replyMessageContent = messages.byId[replyMessageID]?.content;

    const editMessageDate = messages.byId[editMessageID]?.time?.toDate()
        .toLocaleDateString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        })
    const editMessageContent = messages.byId[editMessageID]?.content;

    const targetAmbassador = ambassadors.byId[activeAmbassadorID];
    const ambassadorName = targetAmbassador?.firstName || "Ambassador";

    useEffect(() => {
        if (!!replyMessageID) {
            setEditMessageID("");
        }
    }, [replyMessageID]);

    useEffect(() => {
        if (!!editMessageID) {
            setReplyMessageID("");
            setBody(messages.byId[editMessageID]?.content);
        } else {
            setBody("");
        }
    }, [editMessageID]);

    useEffect(() => {
        if (body.length > 1000) {
            setMessageTooLongWarning("Message is too long. Please keep it under 1000 characters.");
            logAnalyticsEvent('message_too_long', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
                content: body,
            });
        } else {
            setMessageTooLongWarning("");
        }
    }, [body]);

    useEffect(() => {
        if (tooManyMessagesWarning) {
            logAnalyticsEvent('too_many_messages', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
            });
        }
    }, [tooManyMessagesWarning]);

    useEffect(() => {
        setNotAvailableMessageSentTime(null);
    }, [activeAmbassadorID, activeThreadID]);

    useEffect(() => {
        if (messagesRef.current) {
            const container = messagesRef.current;
            const scrollElement = container.getScrollElement();

            if (scrollElement) {
                setTimeout(() => {
                    // Check if scrollElement is still available
                    console.log("Scrolling to bottom");
                    if (scrollElement) {
                        scrollElement.scrollTop = container.el.scrollHeight;
                    }
                }, 150);
            }
        }
    }, [activeThreadID, messagesRef]);

    console.log("AI Messages: ", aiMessages);
    console.log("Matchmaker Messages: ", matchmakerMessages);

    return (
        <Stack
            sx={{
                flexGrow: 1,
                overflow: 'hidden'
            }}
            {...other}>
            <ChatThreadToolbar schoolName={activeSchool?.name} prospectMuted={prospectMuted} onMuteToggle={handleMute} onToggleSideBar={onToggleSideBar} showToggle={showToggle} targetAmbassadorID={activeAmbassadorID} ambassadors={ambassadors} />
            <Divider />
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    height: "60vh",
                }}
            >
                {
                    !!user && (
                        <ChatPreMessageForm open={showPreMessageQuestions} onClose={() => setShowPreMessageQuestions(false)} onSkip={() => setPreMessageQuestionsSkipped(true)} ambassadorName={isAiChat || isMatchmaker ? "I" : targetAmbassador?.firstName} activeSchool={activeSchool} />
                    )
                }
                {
                    !!user && (
                        <ChatPostMessageForm open={showPostMessageQuestions} onClose={() => setShowPostMessageQuestions(false)} onSkip={() => setPostMessageQuestionsSkipped(true)} ambassadorName={isAiChat || isMatchmaker ? "the bot" : targetAmbassador?.firstName} activeSchool={activeSchool} onSubmit={handleCommunicationsPreferences} />
                    )
                }
                <Scrollbar
                    ref={messagesRef}
                    sx={{ maxHeight: '100%' }}
                >
                    <ChatMessages
                        onChatWith={onChatWith}
                        onTabChange={onTabChange}
                        onTurnNotificationsOn={onTurnNotificationsOn}
                        onAiChat={onAiChat}
                        onMatchmaker={onMatchmaker}
                        isAiChat={isAiChat}
                        isMatchmaker={isMatchmaker}
                        activeThreadID={activeThreadID}
                        onReply={(messageID) => {
                            setReplyMessageID(messageID);
                        }}
                        onEdit={(messageID) => {
                            setEditMessageID(messageID);
                        }}
                        messages={((!isAiChat && messages.allIds.length > 0) || (isAiChat && aiMessages.allIds.length > 0) || isMatchmaker) ? [
                            {
                                id: '5e867f0a5bc0ff2bfa07bfa6',
                                attachments: [],
                                body: isMatchmaker ? !!user ? `I'm stoked to match you with the ambassador that best resonates with you! Let's get started. Tell me what kind of stuff interests you - hobbies, sports, shows - anything!` : `Hey! My job is to match you with the ambassador that best resonates with you. First I'll have you sign in - it takes just 2 clicks!` : isAiChat ? `Hey! I'll try my best to help you with any questions about ${activeSchool.name}. Ask away!` : ambassadors.byId[activeAmbassadorID]?.profileIntroText || "Hey! Got any questions for me?",
                                contentType: 'text',
                                authorId: activeAmbassadorID,
                                createdAt: null,
                            },
                            ...Object.entries(isAiChat ? aiMessages.byId : isMatchmaker ? matchmakerMessages.byId : messages.byId).map(
                                ([key, message]) => ({
                                    id: key,
                                    attachments: [],
                                    body: isAiChat || isMatchmaker ? message.body : message.content,
                                    contentType: 'text',
                                    authorId: isAiChat || isMatchmaker ? message.authorId : message.sender.id,
                                    createdAt: isAiChat || isMatchmaker ? message.createdAt : message.time.toDate().getTime(),
                                    replyMessage: message.replyMessage,
                                    reactions: message.reactions,
                                    isEdited: message.isEdited,
                                    queryID: message.queryID,
                                    helpful: message.helpful,
                                    matchedAmbassador: message.matchedAmbassador,
                                })
                            ),
                            ...((!!notAvailableMessageSentTime && !ambassadorAvailable) ? [
                                {
                                    id: `${activeAmbassadorID}-not-available`,
                                    attachments: [],
                                    // TODO if they haven't updated their contact info, tell them to as main priority
                                    body: `Hey there! I'm currently not available to chat. In the meantime, feel free to ask me any questions you have and keep your notifications turned on- I'll get back to you as soon as I can! You can also check out the list of other ambassadors available to chat, or ask our AI Bot to get instant responses. Talk to you soon!`,
                                    contentType: 'text',
                                    authorId: activeAmbassadorID,
                                    createdAt: notAvailableMessageSentTime.getTime(),
                                }
                            ] : [])
                        ] : [
                            {
                                id: '5e867f0a5bc0ff2bfa07bfa6',
                                attachments: [],
                                body: isAiChat ? `Hey! I'll try my best to help you with any questions about ${activeSchool.name}. Ask away!` : ambassadors.byId[activeAmbassadorID]?.profileIntroText || "Hey! Got any questions for me?",
                                contentType: 'text',
                                authorId: activeAmbassadorID,
                                createdAt: new Date().getTime(),
                            }
                        ]}
                        ambassadors={ambassadors || []}
                        ambassadorName={isAiChat ? 'AI Bot' : ambassadorName}
                        contributorsAddedDates={activeThread?.addedDates}
                    />
                </Scrollbar>
            </Box>
            <Divider />
            {!!replyMessageSender && !!replyMessageContent && (
                <ChatMessageReply onCancel={handleReplyCancel} replyMessageSender={replyMessageSender} replyMessageContent={replyMessageContent} />
            )}
            {!!editMessageDate && !!editMessageContent && (
                <ChatMessageEdit onCancel={handleEditCancel} editMessageDate={editMessageDate} editMessageContent={editMessageContent} />
            )}
            <ChatMessageAdd sendDisabled={!!tooManyMessagesWarning || !!messageTooLongWarning || sendLoading} disabled={!!tooManyMessagesWarning || sendLoading} body={body} onMessageChange={handleChange} onSend={handleSend} />
            {
                !!tooManyMessagesWarning && (
                    <Box sx={{ color: "error.main", textAlign: "center", fontSize: "0.8em" }}>
                        {tooManyMessagesWarning}
                    </Box>
                )
            }
            {
                !!messageTooLongWarning && (
                    <Box sx={{ color: "error.main", textAlign: "center", fontSize: "0.8em" }}>
                        {messageTooLongWarning}
                    </Box>
                )
            }
        </Stack>
    );
};

ChatThread.propTypes = {
    activeAmbassadorID: PropTypes.string.isRequired,
    ambassadors: PropTypes.object,
    activeThreadID: PropTypes.string,
    activeThread: PropTypes.object,
    onTabChange: PropTypes.func,
    onChatWith: PropTypes.func,
    onToggleSideBar: PropTypes.func,
    showToggle: PropTypes.bool,
    prospectMuted: PropTypes.bool,
    onTurnNotificationsOn: PropTypes.func,
    onAiChat: PropTypes.func,
    onMatchmaker: PropTypes.func,
};
